import React, { Component, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { FormFeedback } from 'reactstrap';
import classNames from 'classnames';

class CreditCardExpiryInput extends Component {
    handleChange = e => {
        const [month = '', year = ''] = e.target.value.split(' / ');
        this.props.onChange({ month: month.trim(), year: year.trim() });
    };

    getValue() {
        const { month, year } = this.props.value;
        return `${month} / ${year}`;
    }

    render() {
        const { error, className, ...rest } = this.props;

        return (
            <Fragment>
                <NumberFormat
                    className={classNames('form-control', className, { 'is-invalid': error })}
                    format="## / ##"
                    placeholder="MM / YY"
                    {...rest}
                    onChange={this.handleChange}
                    value={this.getValue()}
                />

                <FormFeedback>{error}</FormFeedback>
            </Fragment>
        );
    }
}

export default CreditCardExpiryInput;
