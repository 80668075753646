import { FETCH_BUTTON_SUCCEEDED } from '../actions/buttonActions';
import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

export const initialState = {
    loading: false,
    data: {},
};

const buttonReducer = createReducer(initialState, {
    [FETCH_BUTTON_SUCCEEDED](state, { button }) {
        const data = _.omit(button, 'seller');
        state.data = { ...data, items: data.items.map(item => _.pick(item, 'product.id')) };
    },
});

export default buttonReducer;
