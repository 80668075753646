import OtherPaymentMethodButton from './OtherPaymentMethodButton';
import { PaymentMethod } from '../../actions/giftActions';
import PaymentMethodButton from './PaymentMethodButton';
import React, { Component } from 'react';
import FormField from '../FormField';
import chipImage from '../../images/terminal.svg';
import styles from './PaymentMethodSelector.module.scss';
import classNames from 'classnames';

class PaymentMethodSelector extends Component {
    handleClick = method => () => {
        this.props.onChange(method);
    };

    getSelectedPaymentMethod() {
        const { paymentMethods, method } = this.props;
        return paymentMethods.find(paymentMethod => paymentMethod.id === method);
    }

    shouldShowComment(method) {
        return method !== PaymentMethod.CREDIT_CARD && method !== PaymentMethod.INTENT;
    }

    render() {
        const { className, onChange, method, paymentMethods, canUseEMVPayment } = this.props;
        const selectedPaymentMethod = this.getSelectedPaymentMethod();

        return (
            <div>
                {canUseEMVPayment ? (
                    <PaymentMethodButton
                        title="Terminal"
                        icon={<img className={classNames(styles.icon, 'img-fluid')} alt="chip" src={chipImage} />}
                        onClick={this.handleClick(PaymentMethod.INTENT)}
                        checked={method === PaymentMethod.INTENT}
                        className={className}
                    />
                ) : null}
                <PaymentMethodButton
                    title="Card"
                    icon={<i className="text-primary fas fa-credit-card" />}
                    onClick={this.handleClick(PaymentMethod.CREDIT_CARD)}
                    checked={method === PaymentMethod.CREDIT_CARD}
                    className={className}
                />

                <PaymentMethodButton
                    title="Cash"
                    icon={<i className="text-success fas fa-money-bill-alt" />}
                    onClick={this.handleClick(PaymentMethod.CASH)}
                    checked={method === PaymentMethod.CASH}
                    className={className}
                />

                <PaymentMethodButton
                    title="Check"
                    icon={<i className="text-secondary fas fa-money-check" />}
                    onClick={this.handleClick(PaymentMethod.CHECK)}
                    checked={method === PaymentMethod.CHECK}
                    className={className}
                />

                <OtherPaymentMethodButton
                    paymentMethods={paymentMethods}
                    onChange={onChange}
                    method={method}
                    className={className}
                />

                {this.shouldShowComment(method) ? (
                    <FormField
                        name="payment.comment"
                        placeholder={selectedPaymentMethod ? selectedPaymentMethod.comment.title : 'Comment'}
                    />
                ) : null}
            </div>
        );
    }
}

export default PaymentMethodSelector;
