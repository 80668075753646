import { ModalBody, Spinner } from 'reactstrap';
import React, { Component } from 'react';
import Modal from './Modal/Modal';

class LoadingModal extends Component {
    render() {
        const { children, ...rest } = this.props;

        return (
            <Modal {...rest}>
                <ModalBody className="text-center d-flex align-items-center justify-content-center">
                    <Spinner color="accent" className="mr-3" /> {children}
                </ModalBody>
            </Modal>
        );
    }
}

export default LoadingModal;
