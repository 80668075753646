import CouponScheduleRestriction from './CouponScheduleRestriction';
import CouponRestriction from './CouponRestriction';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Date from '../Date';

class CouponRestrictions extends Component {
    getAppliesToText() {
        const { couponRestrictions, experiences } = this.props;

        return couponRestrictions.experiences.all
            ? 'All Listings'
            : couponRestrictions.experiences.experiences
                  .filter(id => experiences[id])
                  .map(id => experiences[id].name)
                  .join(', ');
    }

    render() {
        const { couponRestrictions, className } = this.props;

        if (!couponRestrictions) {
            return null;
        }

        return (
            <div className={className}>
                {couponRestrictions.arrivalSchedule?.id ? (
                    <CouponScheduleRestriction title="Redeemable" schedule={couponRestrictions.arrivalSchedule} />
                ) : null}
                {couponRestrictions.arrivalSchedule?.blackouts ? (
                    <CouponScheduleRestriction
                        title="Blackout"
                        schedule={couponRestrictions.arrivalSchedule.blackouts}
                    />
                ) : null}
                {couponRestrictions.experiences ? (
                    <CouponRestriction title="Applies To">{this.getAppliesToText()}</CouponRestriction>
                ) : null}

                {couponRestrictions.bookBySchedule?.end ? (
                    <span className="badge badge-danger">
                        <i className="fas fa-calendar-alt mr-1" /> <span>Offer Ends: </span>
                        <Date>{couponRestrictions.bookBySchedule.end}</Date>
                    </span>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    products: state.products.data,
    experiences: state.products.experiences,
});

export default connect(mapStateToProps)(CouponRestrictions);
