import { isGiftItemBaseAmountValid } from '../selectors/giftSelector';
import { canUseEMVPayment } from '../selectors/sellerSelectors';
import RecipientForm from '../components/RecipientForm';
import { PaymentMethod, updateGiftItem, changePaymentMethod } from '../actions/giftActions';
import Stepper, { Step } from '../components/Stepper';
import SellerLogo from '../components/SellerLogo';
import GiftItem from '../components/GiftItem';
import Legend from '../components/Legend';
import Header from '../components/Header';
import { navigate } from '@reach/router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from '../components/Page';

class PersonalizationPage extends Component {
    componentDidMount() {
        if (!this.props.giftItem) {
            navigate('/');
        }

        if (this.props.canUseEMVPayment) {
            this.props.changePaymentMethod(PaymentMethod.INTENT);
        }
    }

    handleRecipientFormConfirm = giftItem => {
        const { updateGiftItem, giftItemIndex } = this.props;
        updateGiftItem(giftItemIndex, giftItem);
        navigate('/payment');
    };

    handleGiftItemChange = (giftItemIndex, giftItem) => {
        this.props.updateGiftItem(giftItemIndex, giftItem);
    };

    render() {
        const { giftItem, giftItemIndex, isGiftItemBaseAmountValid, edit, context } = this.props;

        if (!giftItem) {
            return null;
        }

        return (
            <Page>
                <Header title="Personalize Gift" left={<SellerLogo />}>
                    <Stepper>
                        <Step done>1</Step>
                        <Step current>2</Step>
                        <Step>3</Step>
                    </Stepper>
                </Header>

                <div className="px-3 px-sm-5 pb-3 pb-sm-5">
                    <Legend className="mb-5">Gift Details</Legend>

                    <div className="mb-5">
                        <GiftItem
                            giftItem={giftItem}
                            giftItemIndex={giftItemIndex}
                            onChange={this.handleGiftItemChange}
                            showInput
                        />
                    </div>

                    <Legend className="mb-5">To</Legend>

                    <RecipientForm
                        giftItem={giftItem}
                        onConfirm={this.handleRecipientFormConfirm}
                        disableSubmit={!isGiftItemBaseAmountValid}
                        showBackButton={!edit}
                        context={context}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = (state, props) => ({
    gift: state.gift,
    canUseEMVPayment: canUseEMVPayment(state),
    giftItem: state.gift.items[props.giftItemIndex],
    isGiftItemBaseAmountValid: isGiftItemBaseAmountValid(state, props.giftItemIndex),
    context: state.bootstrap.context,
});

const mapDispatchToProps = {
    updateGiftItem,
    changePaymentMethod,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizationPage);
