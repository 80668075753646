import _ from 'lodash';

/**
 * Nicely format server error message types to display them to the user.
 *
 * @param {string} message Example `must_be_positive`.
 * @param {string} key Example `amount`.
 * @returns {string} Example `Amount: Must Be Positive`.
 */
const formatErrorMessage = (message, key) => {
    if (key) {
        return _.startCase(key) + ': ' + _.startCase(message);
    }

    return _.startCase(message);
};

/**
 * Get human-readable error message from the response `field` or `global` object.
 *
 * @param {object} data Response object.
 */
const formatResponseError = data => {
    const field = data.field || data.global;
    const [key] = _.keys(field);
    const error = field[key];

    if (_.isString(error)) {
        return formatErrorMessage(error, _.isArray(field) ? null : key);
    }

    if (_.has(error, 'message')) {
        return error.message;
    }

    if (_.has(error, 'reason')) {
        return formatErrorMessage(error.reason, key);
    }

    return 'Sorry, something went wrong';
};

export default formatResponseError;
