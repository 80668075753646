import { ListItem, ListItemImage, ListItemContent } from '../List';
import Currency, { CurrencyInput } from '../Currency';
import GiftItemBreakdown from './GiftItemBreakdown';
import React, { Component, Fragment } from 'react';
import GiftItemButtons from './GiftItemButtons';
import GiftCodeInput from '../GiftCodeInput';
import ProductImage from '../ProductImage';
import { Row, Col } from 'reactstrap';
import CustomGiftAmountValidationError from '../CustomGiftAmountValidationError';
import { getGiftItemAmount } from '../../selectors/giftSelector';
import { connect } from 'react-redux';
import { getMinimumCustomAmount, getMaximumCustomAmount } from '../../selectors/giftSelector';

class CustomAmountGiftItem extends Component {
    handleAmountChange = values => {
        const { onChange, giftItemIndex } = this.props;
        onChange(giftItemIndex, { baseAmount: values.floatValue || 0 });
    };

    handleCodeChange = code => {
        const { onChange, giftItemIndex } = this.props;
        onChange(giftItemIndex, { code });
    };

    render() {
        const {
            giftItemIndex,
            giftItem,
            giftItemAmount,
            product,
            showEditButton,
            showDeleteButton,
            showCode,
            disableCode,
            showInput,
            isDuplicateCode,
            maximimCustomAmount,
            minimimCustomAmount,
        } = this.props;

        const isInvalid = giftItem.baseAmount < minimimCustomAmount || giftItemAmount > maximimCustomAmount;

        return (
            <ListItem padding={false}>
                <ListItemImage>
                    <ProductImage product={product} />

                    <GiftItemButtons
                        giftItemIndex={giftItemIndex}
                        showEditButton={showEditButton}
                        showDeleteButton={showDeleteButton}
                    />
                </ListItemImage>

                <ListItemContent>
                    <Row>
                        <Col className="mb-2 mb-md-0" md={6}>
                            <h5>
                                <Currency>{giftItem.baseAmount}</Currency> Gift Card
                            </h5>

                            {showCode ? (
                                <GiftCodeInput
                                    style={{ maxWidth: 200 }}
                                    onChange={this.handleCodeChange}
                                    value={giftItem.code}
                                    disabled={disableCode}
                                    error={isDuplicateCode ? 'Duplicate code' : null}
                                />
                            ) : null}

                            {showInput ? (
                                <Fragment>
                                    <CurrencyInput
                                        style={{ maxWidth: 200 }}
                                        isInvalid={isInvalid}
                                        onChange={this.handleAmountChange}
                                        value={giftItem.baseAmount}
                                    />

                                    {isInvalid ? (
                                        <CustomGiftAmountValidationError
                                            baseAmount={giftItem.baseAmount}
                                            giftItemAmount={giftItemAmount}
                                        />
                                    ) : null}
                                </Fragment>
                            ) : null}
                        </Col>

                        <Col md={6}>
                            <GiftItemBreakdown giftItem={giftItem} isCustomAmountBreakdown={true} />
                        </Col>
                    </Row>
                </ListItemContent>
            </ListItem>
        );
    }
}

const mapStateToProps = (state, props) => ({
    giftItemAmount: getGiftItemAmount(state, props.giftItem),
    maximimCustomAmount: getMaximumCustomAmount(state.seller),
    minimimCustomAmount: getMinimumCustomAmount(state.seller),
});

export default connect(mapStateToProps)(CustomAmountGiftItem);
