import ModalFooterButton from './Modal/ModalFooterButton';
import NumberFormat from 'react-number-format';
import ModalFooter from './Modal/ModalFooter';
import { getSymbol } from '../utils/currency';
import { ModalHeader } from 'reactstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Currency from './Currency';
import Modal from './Modal/Modal';
import { getCurrency } from '../selectors/sellerSelectors';

class CashCalculatorModal extends Component {
    state = { amount: 0 };

    isInvalid() {
        if (this.state.amount > 0) {
            return this.state.amount < this.props.expectedAmount;
        }

        return false;
    }

    handleAmountChange = values => {
        this.setState({ amount: values.floatValue || 0 });
    };

    getChangeDue() {
        return Math.max(this.state.amount - this.props.expectedAmount, 0);
    }

    handleAmountFocus = e => {
        e.target.select();
    };

    getSymbol() {
        const { currency } = this.props;
        return getSymbol(currency);
    }

    render() {
        const { isOpen, toggle, expectedAmount, onSubmit } = this.props;
        const { amount } = this.state;
        const currencySymbol = this.getSymbol();

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Enter Cash Tendered</ModalHeader>

                <NumberFormat
                    className="form-control form-control-lg rounded-0 border-0"
                    onValueChange={this.handleAmountChange}
                    prefix={`${currencySymbol} `}
                    onFocus={this.handleAmountFocus}
                    value={amount}
                />

                <div className="bg-primary p-3 text-white d-flex">
                    <h4 className="text-uppercase mr-auto mb-0">Change Due:</h4>

                    <h4 className="mb-0" notranslate="">
                        <Currency>{this.getChangeDue()}</Currency>
                    </h4>
                </div>

                <ModalFooter>
                    <ModalFooterButton
                        className="rounded-0 text-white"
                        size="lg"
                        disabled={this.isInvalid()}
                        color="success"
                        onClick={onSubmit}
                    >
                        {this.isInvalid() ? (
                            <span>
                                Minimum <Currency>{expectedAmount}</Currency>
                            </span>
                        ) : amount ? (
                            'Done'
                        ) : (
                            'Exact Cash Tendered'
                        )}
                    </ModalFooterButton>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    currency: getCurrency(state),
});

export default connect(mapStateToProps)(CashCalculatorModal);
