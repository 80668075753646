import { BootstrapContext } from '../actions/bootstrapActions';
import LoadingButton from '../components/LoadingButton';
import { Button, Col, Row } from 'reactstrap';
import Currency from '../components/Currency';
import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Field } from 'formik';

class PaymentPageButtons extends Component {
    showAddAnotherGiftButton() {
        const { allowMultiItemGifts, context } = this.props;
        return allowMultiItemGifts && context !== BootstrapContext.PHYSICAL_GIFT_CARD;
    }

    render() {
        const { context, onBackClick, onAddAnotherGiftClick, loading, isValid, payableAmount } = this.props;

        return (
            <Field>
                {({ form }) => (
                    <Row>
                        <Col className="mb-4 mb-md-0" md={2} xs={4}>
                            {context !== BootstrapContext.PHYSICAL_GIFT_CARD ? (
                                <Button
                                    aria-label="Back"
                                    onClick={onBackClick(form.values)}
                                    className="py-3"
                                    size="lg"
                                    outline
                                    block
                                >
                                    <i className="fas fa-chevron-left" />
                                </Button>
                            ) : null}
                        </Col>

                        <Col className="mb-4 mb-md-0" md={5} xs={8}>
                            {this.showAddAnotherGiftButton() ? (
                                <Link
                                    to="/"
                                    className="btn btn-outline-secondary btn-lg btn-block text-truncate py-3"
                                    onClick={onAddAnotherGiftClick(form.values)}
                                >
                                    Add Another Gift
                                </Link>
                            ) : null}
                        </Col>

                        <Col className="mb-4 mb-md-0" md={5} xs={12}>
                            <LoadingButton
                                className="py-3 text-white"
                                type="submit"
                                size="lg"
                                color="success"
                                loading={loading}
                                disabled={!isValid}
                                block
                            >
                                Pay <Currency>{payableAmount}</Currency>
                            </LoadingButton>
                        </Col>
                    </Row>
                )}
            </Field>
        );
    }
}

export default PaymentPageButtons;
