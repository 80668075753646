import { format } from '../../utils/currency';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { getCurrency } from '../../selectors/sellerSelectors';

class Currency extends Component {
    static propTypes = {
        currency: PropTypes.string,
        children: PropTypes.number,
    };

    render() {
        const { currency, children: amount = 0 } = this.props;
        const formattedAmount = format(amount, currency);
        return <span notranslate="">{formattedAmount}</span>;
    }
}

const mapStateToProps = state => ({
    currency: getCurrency(state),
});

export default connect(mapStateToProps)(Currency);
