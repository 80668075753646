import { removeGiftItem } from '../../actions/giftActions';
import ModalFooterButton from '../Modal/ModalFooterButton';
import { ModalHeader, ModalBody } from 'reactstrap';
import React, { Component, Fragment } from 'react';
import styles from './GiftItemButtons.module.scss';
import ModalFooter from '../Modal/ModalFooter';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import Modal from '../Modal/Modal';

class GiftItemButtons extends Component {
    state = { showDeleteModal: false };

    handleToggleDeleteModal = () => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
    };

    handleDeleteClick = () => {
        const { giftItemIndex, removeGiftItem } = this.props;
        removeGiftItem(giftItemIndex);
        this.handleToggleDeleteModal();
    };

    render() {
        const { giftItemIndex, showEditButton, showDeleteButton } = this.props;
        const { showDeleteModal } = this.state;

        return (
            <div className="pt-2 text-center">
                {showEditButton ? (
                    <Link className={styles.button} to={`/personalize/${giftItemIndex}/edit`}>
                        Edit
                    </Link>
                ) : null}

                {showDeleteButton ? (
                    <Fragment>
                        {showEditButton ? <span className="d-inline-block mx-1">&middot;</span> : null}

                        <span className={styles.button} onClick={this.handleToggleDeleteModal} role="button">
                            Delete
                        </span>
                    </Fragment>
                ) : null}

                <Modal isOpen={showDeleteModal} toggle={this.handleToggleDeleteModal}>
                    <ModalHeader toggle={this.handleToggleDeleteModal}>Confirm Delete</ModalHeader>
                    <ModalBody>Are you sure you want to delete the selected item?</ModalBody>

                    <ModalFooter>
                        <ModalFooterButton color="danger" onClick={this.handleDeleteClick} size="lg" block>
                            Delete
                        </ModalFooterButton>

                        <ModalFooterButton
                            color="secondary"
                            onClick={this.handleToggleDeleteModal}
                            size="lg"
                            outline
                            block
                        >
                            Cancel
                        </ModalFooterButton>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    removeGiftItem,
};

export default connect(null, mapDispatchToProps)(GiftItemButtons);
