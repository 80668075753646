import { ModalFooter as BootstrapModalFooter } from 'reactstrap';
import React, { Component } from 'react';
import classNames from 'classnames';

class ModalFooter extends Component {
    render() {
        const { className, ...rest } = this.props;
        return <BootstrapModalFooter className={classNames('p-0 d-flex overflow-hidden', className)} {...rest} />;
    }
}

export default ModalFooter;
