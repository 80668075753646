import React, { Component } from 'react';
import { Spinner } from 'reactstrap';

class Loader extends Component {
    static defaultProps = {
        delay: 300,
    };

    state = {
        show: false,
    };

    updateShow() {
        const { show, delay } = this.props;

        if (show) {
            this.timeout = setTimeout(() => this.setState({ show: true }), delay);
        } else {
            clearTimeout(this.timeout);
            this.setState({ show: false });
        }
    }

    componentDidMount() {
        this.updateShow();
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
            this.updateShow();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { show, className, ...rest } = this.props;

        return this.state.show ? (
            <div className={className}>
                <Spinner {...rest} />
            </div>
        ) : null;
    }
}

export default Loader;
