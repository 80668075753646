import { getVisibleProducts } from '../selectors/productsSelector';
import { addGiftItem } from '../actions/giftActions';
import React, { Component, Fragment } from 'react';
import { navigate } from '@reach/router';
import ProductItem from './ProductItem';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import Loader from './Loader';

class ProductList extends Component {
    state = { selectedIndex: 0 };

    handleClickProduct = selectedIndex => () => {
        this.setState({ selectedIndex });
    };

    handleSelectProduct = (product, baseAmount) => {
        const { addGiftItem, gift } = this.props;
        addGiftItem(product, { baseAmount });
        navigate(`personalize/${gift.items.length}`);
    };

    render() {
        const { products, loading } = this.props;
        const { selectedIndex } = this.state;

        if (!loading && products.length === 0) {
            return (
                <Alert className="text-center mx-3" color="secondary">
                    No available gift options
                </Alert>
            );
        }

        return (
            <Fragment>
                <Loader className="text-center mb-5" color="accent" show={loading} delay={0} />

                {products.map((product, index) => (
                    <ProductItem
                        key={index}
                        product={product}
                        onSelect={this.handleSelectProduct}
                        onClick={this.handleClickProduct(index)}
                        isSelected={selectedIndex === index}
                    />
                ))}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    gift: state.gift,
    products: getVisibleProducts(state),
    loading: state.products.loading,
});

const mapDispatchToProps = {
    addGiftItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
