export const FeeScope = {
    PERSON: 'person',
    OUTING: 'outing',
    ADDON: 'addon',
};

export const FeeScopeModifier = {
    PER_DAY: 'day',
    EXCLUDE_ADDON: 'excludeAddon',
    ADDON_ONLY: 'addonsOnly',
};
