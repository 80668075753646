import { object, string, setLocale } from 'yup';

setLocale({
    mixed: {
        required: 'Required',
    },
});

const recipientSchema = object().shape({
    recipientName: string().required(),

    recipientEmail: string()
        .email('Invalid email')
        .when('sendToRecipient', {
            is: true,
            then: string().required(),
        }),
});

export default recipientSchema;
