import React, { Component, Fragment } from 'react';
import styles from './Step.module.scss';
import { Link } from '@reach/router';
import classNames from 'classnames';

const StepLink = ({ to, ...props }) => {
    return to ? <Link to={to} {...props} /> : <div {...props} />;
};

export default class Step extends Component {
    render() {
        const { children, last, current, done, to, size } = this.props;

        return (
            <Fragment>
                <StepLink
                    to={to}
                    style={{ width: size, height: size, maxWidth: size, maxHeight: size }}
                    className={classNames(
                        'd-flex align-items-center justify-content-center rounded-circle border border-dark',
                        { 'border-accent bg-accent text-white': current },
                    )}
                >
                    {done ? <i className="fa fa-check" /> : children}
                </StepLink>

                {last ? null : (
                    <div className={classNames(styles.line, { [styles.lineCurrent]: current, 'bg-dark': done })} />
                )}
            </Fragment>
        );
    }
}
