import config from '../config';
import axios from 'axios';

const xolaApi = axios.create({
    baseURL: config.xola.url,
    headers: { 'X-API-VERSION': config.xola.version },
});

let store;

xolaApi.interceptors.request.use(config => {
    if (store) {
        const { user, captcha, bootstrap } = store.getState();

        if (user) {
            config.headers['X-API-KEY'] = user.apiKey;
        }

        if (captcha.response) {
            config.headers['X-RECAPTCHA-RESPONSE'] = captcha.response;
        }

        if (bootstrap.locale) {
            config.headers['Accept-Language'] = bootstrap.locale;
        }

        if (bootstrap.seller && config.method === 'get') {
            if (config.params) {
                config.params.seller = bootstrap.seller;
            } else {
                config.params = { seller: bootstrap.seller };
            }
        }
    }

    return config;
});

xolaApi.setStore = reduxStore => {
    store = reduxStore;
};

export default xolaApi;
