import React, { Component, Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';

class CreditCardFormLayout extends Component {
    render() {
        const { name, number, cvv, expiry, zip } = this.props;

        return (
            <Fragment>
                <FormGroup>{name}</FormGroup>

                <Row>
                    <Col xs={12} md={9} className="pr-md-0">
                        <FormGroup>{number}</FormGroup>
                    </Col>

                    <Col xs={12} md={3}>
                        <FormGroup>{cvv}</FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={9} className="pr-md-0">
                        <FormGroup>{expiry}</FormGroup>
                    </Col>

                    <Col xs={12} md={3}>
                        <FormGroup>{zip}</FormGroup>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default CreditCardFormLayout;
