import { CardElement } from 'react-stripe-elements';
import React, { Component } from 'react';
import { getEnforcedPostalCode } from '../../selectors/sellerSelectors';
import { connect } from 'react-redux';
import { setGiftError } from '../../actions/giftActions';

class StripeElementsForm extends Component {
    createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding,
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };

    handleChange = () => {
        this.props.setGiftError('');
    };

    render() {
        return (
            <div>
                <CardElement
                    onChange={this.handleChange}
                    hidePostalCode={!this.props.enforcePostalCode}
                    {...this.createOptions('18px', '10px 14px')}
                >
                    {' '}
                </CardElement>
                <div className="is-invalid invalid-feedback" style={{ display: 'block' }}>
                    {' '}
                    {this.props.stripeError}{' '}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    enforcePostalCode: getEnforcedPostalCode(state),
    stripeError: state.gift.error,
});

const mapDispatchToProps = {
    setGiftError,
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeElementsForm);
