import PersonalizationPage from '../../pages/PersonalizationPage';
import ProductsPage from '../../pages/ProductsPage';
import PaymentPage from '../../pages/PaymentPage';
import SuccessPage from '../../pages/SuccessPage';
import PrintPage from '../../pages/PrintPage';
import React, { Component } from 'react';
import ScrollToTop from './ScrollToTop';
import { Router } from '@reach/router';

class AppRouter extends Component {
    render() {
        // Path `/cart` is for backward compatibility with the old app.
        return (
            <Router primary={false}>
                <ScrollToTop path="/">
                    <ProductsPage path="/" />
                    <ProductsPage path="/cart" />
                    <PersonalizationPage path="/personalize/:giftItemIndex" />
                    <PersonalizationPage path="/personalize/:giftItemIndex/edit" edit />
                    <PaymentPage path="/payment" />
                    <SuccessPage path="/success" />
                    <PrintPage path="/print/:giftItemIndex" />
                </ScrollToTop>
            </Router>
        );
    }
}

export default AppRouter;
