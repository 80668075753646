import styles from './ListItemImage.module.scss';
import React, { Component } from 'react';
import classNames from 'classnames';

class ListItemImage extends Component {
    render() {
        return <div className={classNames(styles.main, 'mr-4 mr-xl-5')}>{this.props.children}</div>;
    }
}

export default ListItemImage;
