import config from './config';
import getUserLocale from 'get-user-locale';

/**
 * Get language code from locale string.
 * @param {string} locale Example: "en-US"
 * @returns {string} Example: "en"
 */
export const getLanguageCode = locale => {
    const [language] = locale.replace('_', '-').split('-');
    return language.toLowerCase();
};

/**
 * Get app language for the user.
 * @param {Object} seller
 * @returns {string}
 */
const getLanguage = seller => {
    const userLanguage = getLanguageCode(getUserLocale());
    const { supportedLanguages = [] } = seller.preferences.language;

    if (supportedLanguages.find(language => getLanguageCode(language.locale) === userLanguage)) {
        return userLanguage;
    }

    return 'en';
};

const localize = (seller, selectedLanguageCode) => {
    const options = {
        key: config.localizejs.key,
        saveNewPhrases: config.localizejs.saveNewPhrases,
    };
    const language = selectedLanguageCode ?? getLanguage(seller);
    options.targetLanguage = language;
    if (language !== 'en') {
        options.autodetectLanguage = false;
    }

    if (config.localizejs.enabled && (seller.preferences.language.multiple || language !== 'en')) {
        window.Localize.initialize(options);
    }
};

export default localize;
