export const ADD_PRODUCT = 'ADD_PRODUCT';

export const addProduct = product => ({
    type: ADD_PRODUCT,
    product,
});

export const ProductObject = {
    EXPERIENCE: 'experience',
    GIFT_OFFER: 'gift_offer',
    MEMBERSHIP: 'membership',
};

export const GiftAmountProduct = {};
