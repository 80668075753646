import { GiftAmountProduct } from '../actions/productActions';
import { getDiscountAmount, getProductDiscountAmount } from './productsSelector';
import { GiftOfferType } from '../actions/giftOfferActions';
import { getProductFeeAmount } from './feeSelector';
import { round } from 'mathjs';
import _ from 'lodash';
import { getBookingFeeAmount } from './sellerSelectors';
import { getPriceScheme, getPriceTypeConstraint, PriceSchemePriceType } from './experienceSelector';
import { convertToCurrency } from '../utils/currency';

const MINIMUM_AMOUNT_USD = 1;
const MAXIMUM_AMOUNT_USD = 2000;

export const getGiftItemProduct = (state, giftItemIndex) => {
    const giftItem = state.gift.items[giftItemIndex];

    if (giftItem) {
        if (giftItem.product) {
            const experience = state.products.experiences[giftItem.product.id];
            return experience ? getGiftOfferByExperience(state, experience) : state.products.data[giftItem.product.id];
        }

        return state.baseGiftProduct || GiftAmountProduct;
    }

    return null;
};

export const isCustomerEmailRequired = state => {
    return state.gift.items.some(item => !item.sendToRecipient);
};

export const getGiftItemAmount = (state, giftItem, includeBookingFee) => {
    if (giftItem.product && giftItem.product.id) {
        const experience = state.products.experiences[giftItem.product.id];
        const product = experience
            ? getGiftOfferByExperience(state, experience)
            : state.products.data[giftItem.product.id];

        var baseAmount = giftItem.baseAmount - getProductDiscountAmount(product, giftItem.baseAmount);

        if (product.experience) {
            const constraint = getPriceTypeConstraint(product.experience);

            if (constraint && constraint.priceType !== PriceSchemePriceType.OUTING) {
                baseAmount = _.sumBy(giftItem.demographics, demographic => {
                    if (demographic.quantity > 0) {
                        const priceScheme = getPriceScheme(product.experience, demographic.quantity);
                        const demographicPrice = demographic.discount
                            ? priceScheme.price - getDiscountAmount(priceScheme.price, demographic.discount)
                            : priceScheme.price;
                        return constraint.priceType === PriceSchemePriceType.PERSON
                            ? demographicPrice * demographic.quantity
                            : demographicPrice;
                    }
                });
            }
        }

        return round(
            baseAmount + getProductFeeAmount(state, product, baseAmount, includeBookingFee, giftItem.quantity),
            2,
        );
    }

    return round(
        giftItem.baseAmount +
            getProductFeeAmount(state, GiftAmountProduct, giftItem.baseAmount, includeBookingFee, giftItem.quantity),
        2,
    );
};

export const getGiftAmount = state => {
    const gift = state.payment.isCollecting ? state.request : state.gift;

    const giftValue = _.sumBy(gift.items, giftItem => {
        return getGiftItemAmount(state, giftItem, false);
    });

    const includeBookingFee = !!_.find(state.seller.partnerFeeFormulas, {
        orderAmountIncludesPartnerFee: true,
    });
    if (includeBookingFee) {
        return giftValue + getBookingFeeAmount(state, giftValue);
    }

    return giftValue;
};

export const getPayableAmount = state => {
    const gift = state.payment.isCollecting ? state.request : state.gift;

    const giftValue = _.sumBy(gift.items, giftItem => {
        return getGiftItemAmount(state, giftItem, false);
    });

    const orderAmountIncludesPartnerFee = !!_.find(state.seller.partnerFeeFormulas, {
        orderAmountIncludesPartnerFee: true,
    });
    const partnerFeePaidByTraveler = !!_.find(state.seller.partnerFeeFormulas, {
        source: 'gift',
        type: 'traveler',
    });
    const includeBookingFee = orderAmountIncludesPartnerFee || partnerFeePaidByTraveler;
    if (includeBookingFee) {
        return giftValue + getBookingFeeAmount(state, giftValue);
    }

    return giftValue;
};

export const getGiftBaseAmount = state => {
    return _.sumBy(state.gift.items, 'baseAmount');
};

export const isGiftItemBaseAmountValid = (state, giftItemIndex) => {
    const giftItem = state.gift.items[giftItemIndex];
    const product = getGiftItemProduct(state, giftItemIndex);
    if (giftItem.product && giftItem.product.id) {
        if (product.system === false && product.type === GiftOfferType.VARIABLE) {
            const minimumPrice = product.minimumPrice || 0;
            return giftItem.baseAmount >= minimumPrice;
        } else if (product.type === GiftOfferType.FIXED) {
            return true;
        }
    }

    const giftTotalAmount = getGiftItemAmount(state, giftItem);
    // Custom gift items and experience gift items should have limit of 2000$ including taxes and fees

    return (
        giftItem.baseAmount >= getMinimumCustomAmount(state.seller) &&
        giftTotalAmount <= getMaximumCustomAmount(state.seller)
    );
};

export const areGiftItemsValid = state => {
    return (
        getDuplicateCodes(state).length === 0 &&
        state.gift.items.every((giftItem, giftItemIndex) => {
            return isGiftItemBaseAmountValid(state, giftItemIndex);
        })
    );
};

export const getDuplicateCodes = state => {
    return state.gift.items
        .map(giftItem => giftItem.code)
        .filter((code, i, codes) => code && codes.indexOf(code, i + 1) >= 0);
};

export const getGiftOfferByExperience = (state, experience) => {
    for (const giftOfferId in state.products.data) {
        let giftOffer = state.products.data[giftOfferId];
        if (experience && giftOffer.experience && giftOffer.experience.id === experience.id) {
            return giftOffer;
        }
    }
    return null;
};

export const getMaximumCustomAmount = seller => {
    try {
        return Math.floor(convertToCurrency(MAXIMUM_AMOUNT_USD, seller.currency) / 100) * 100; // round to nearest 100
    } catch (error) {
        return MAXIMUM_AMOUNT_USD;
    }
};

export const getMinimumCustomAmount = seller => {
    try {
        return Math.ceil(convertToCurrency(MINIMUM_AMOUNT_USD, seller.currency)); // round up to whole number
    } catch (error) {
        return MINIMUM_AMOUNT_USD;
    }
};
