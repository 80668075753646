import { getDefaultGateway } from '../selectors/sellerSelectors';
import config from '../config';
import axios from 'axios';

const magensaApi = axios.create({
    baseURL: config.magensa.url,
});

let store;

magensaApi.interceptors.request.use(config => {
    if (store) {
        const defaultGateway = getDefaultGateway(store.getState().seller);

        if (defaultGateway.publicKey) {
            config.headers['Authorization'] = `Bearer ${defaultGateway.publicKey}`;
        }
    }

    return config;
});

magensaApi.setStore = reduxStore => {
    store = reduxStore;
};

export default magensaApi;
