import styles from './ListItem.module.scss';
import React, { Component } from 'react';
import classNames from 'classnames';

class ListItem extends Component {
    static defaultProps = {
        padding: true,
        hover: false,
        isSelected: false,
        align: false,
    };

    render() {
        const { className, padding, hover, isSelected, align, children, info, ...rest } = this.props;

        return (
            <div
                tabIndex={0}
                className={classNames(
                    styles.main,
                    { 'p-3 p-xl-4': padding },
                    { [styles.mainHover]: hover },
                    { [styles.mainSelected]: isSelected },
                    className,
                )}
                {...rest}
            >
                <div className={classNames('d-flex', { [styles.row]: align })}>{children}</div>
                {info ? <div className="pt-3">{info}</div> : null}
            </div>
        );
    }
}

export default ListItem;
