import React, { Component } from 'react';
import styles from './Page.module.scss';
import CloseButton from './CloseButton';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Footer from './Footer';

class Page extends Component {
    render() {
        const { children, iframe } = this.props;

        return (
            <div>
                <div
                    className={classNames(
                        styles.page,
                        iframe ? 'container-fluid' : 'container shadow rounded-bottom',
                        'p-0 bg-white overflow-hidden',
                    )}
                >
                    <CloseButton />
                    {children}
                </div>

                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    iframe: state.bootstrap.iframe,
});

export default connect(mapStateToProps)(Page);
