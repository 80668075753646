import CouponRestrictions from '../CouponRestrictions/CouponRestrictions';
import { getGiftItemProduct } from '../../selectors/productsSelector';
import { ListItem, ListItemImage, ListItemContent } from '../List';
import ProductImage from '../ProductImage';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from '@reach/router';
import Currency from '../Currency';

class PurchasedGiftItem extends Component {
    getEmails() {
        const { giftItem, customerEmail } = this.props;
        const emails = [];

        if (giftItem.sendToCustomer) {
            emails.push(customerEmail);
        }

        if (giftItem.sendToRecipient) {
            emails.push(giftItem.recipientEmail);
        }

        return emails.join(' and ');
    }

    render() {
        const { giftItemIndex, giftItem, product } = this.props;

        const info = (
            <span className="text-muted">
                A copy of the gift certificate has been mailed to <var>{this.getEmails()}</var>.
            </span>
        );

        return (
            <ListItem info={info} align>
                <ListItemImage>
                    <ProductImage product={product} />
                </ListItemImage>

                <ListItemContent>
                    <Row className="align-items-center">
                        <Col md={10}>
                            <h5 notranslate="">
                                {product.name ? (
                                    product.name
                                ) : (
                                    <span>
                                        <Currency>{giftItem.baseAmount}</Currency> Gift Card
                                    </span>
                                )}
                            </h5>

                            <p className="mb-2">
                                Gift Code:{' '}
                                <strong className="text-uppercase" notranslate="">
                                    {giftItem.coupon.code}
                                </strong>
                            </p>

                            <CouponRestrictions couponRestrictions={product.couponRestrictions} />
                        </Col>

                        <Col md={2}>
                            <Link to={`/print/${giftItemIndex}`} className="btn btn-outline-secondary btn-block">
                                Print
                            </Link>
                        </Col>
                    </Row>
                </ListItemContent>
            </ListItem>
        );
    }
}

const mapStateToProps = (state, props) => ({
    product: getGiftItemProduct(state, props.giftItem),
    customerEmail: state.gift.customerEmail,
});

export default connect(mapStateToProps)(PurchasedGiftItem);
