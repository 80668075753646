import { FETCH_BUTTON_SUCCEEDED } from '../actions/buttonActions';
import { FETCH_SELLER_SUCCEEDED } from '../actions/sellerActions';
import { createReducer } from 'redux-starter-kit';

export const initialState = [];

const feesReducer = createReducer(initialState, {
    [FETCH_SELLER_SUCCEEDED](state, { seller }) {
        return seller.fees;
    },

    [FETCH_BUTTON_SUCCEEDED](state, { button }) {
        return button.seller.fees;
    },
});

export default feesReducer;
