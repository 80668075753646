import { getGiftAmount, getGiftBaseAmount, getGiftItemAmount, getPayableAmount } from '../selectors/giftSelector';
import { getCurrency, isShowGiftDemographicsEnabled, isThreeDSecureEnabled } from '../selectors/sellerSelectors';
import googleAnalyticsTracker from '../trackers/GoogleAnalytics/googleAnalyticsTracker';
import GoogleAnalyticsGift from '../trackers/GoogleAnalytics/GoogleAnalyticsGift';
import facebookPixelTracker from '../trackers/FacebookPixel/facebookPixelTracker';
import formatResponseError from '../utils/formatResponseError';
import { paymentSuccess, releaseSuccess } from './socketActions';
import { showError } from './errorActions';
import xolaApi from '../api/xolaApi';
import _ from 'lodash';
import { navigate } from '@reach/router';
import { getExperienceDemographics } from '../selectors/productsSelector';
import { BootstrapContext } from './bootstrapActions';

export const UPDATE_GIFT = 'UPDATE_GIFT';
export const ADD_GIFT_ITEM = 'ADD_GIFT_ITEM';
export const UPDATE_GIFT_ITEM = 'UPDATE_GIFT_ITEM';
export const SET_GIFT_ERROR = 'SET_GIFT_ERROR';
export const REMOVE_GIFT_ITEM = 'REMOVE_GIFT_ITEM';
export const REMOVE_UNCOMPLETED_GIFT_ITEMS = 'REMOVE_UNCOMPLETED_GIFT_ITEMS';
export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD';

export const SAVE_GIFT_REQUESTED = 'SAVE_GIFT_REQUESTED';
export const SAVE_GIFT_SUCCEEDED = 'SAVE_GIFT_SUCCEEDED';
export const SAVE_GIFT_FAILED = 'SAVE_GIFT_FAILED';

export const COMMIT_GIFT_REQUESTED = 'COMMIT_GIFT_REQUESTED';
export const COMMIT_GIFT_SUCCEEDED = 'COMMIT_GIFT_SUCCEEDED';
export const COMMIT_GIFT_FAILED = 'COMMIT_GIFT_FAILED';

export const RELEASE_GIFT_REQUESTED = 'RELEASE_GIFT_REQUESTED';
export const RELEASE_GIFT_SUCCEEDED = 'RELEASE_GIFT_SUCCEEDED';
export const RELEASE_GIFT_FAILED = 'RELEASE_GIFT_FAILED';

export const updateGift = gift => ({
    type: UPDATE_GIFT,
    gift,
});

export const addGiftItem = (product, giftItem) => (dispatch, getState) => {
    const giftItemProduct = { id: product.id };
    let experience = product.experience;

    let quantity = experience?.group?.orderMin || 1;
    let demographics = null;
    if (experience) {
        const showDemographics = isShowGiftDemographicsEnabled(getState());

        if (showDemographics) {
            demographics = getExperienceDemographics(experience, quantity);
            demographics[0].quantity = quantity;
        }
    }
    const sendToRecipient =
        getState().bootstrap.context === BootstrapContext.BUTTON ||
        getState().bootstrap.context === BootstrapContext.CHECKOUT;

    dispatch({
        type: ADD_GIFT_ITEM,

        giftItem: {
            product: giftItemProduct,
            code: '',
            recipientName: '',
            recipientEmail: '',
            message: '',
            sendToRecipient,
            demographics: demographics,
            quantity,
            ...giftItem,
        },
    });
};

export const updateGiftItem = (giftItemIndex, giftItem) => ({
    type: UPDATE_GIFT_ITEM,
    giftItemIndex,
    giftItem,
});

export const setGiftError = error => ({
    type: SET_GIFT_ERROR,
    error,
});

export const removeGiftItem = giftItemIndex => ({
    type: REMOVE_GIFT_ITEM,
    giftItemIndex,
});

export const removeUncompletedGiftItems = () => ({
    type: REMOVE_UNCOMPLETED_GIFT_ITEMS,
});

export const changePaymentMethod = method => (dispatch, getState) => {
    dispatch({ type: CHANGE_PAYMENT_METHOD, method, isThreeDSecureEnabled: isThreeDSecureEnabled(getState()) });
};

export const saveGift = gift => async (dispatch, getState) => {
    dispatch({ type: SAVE_GIFT_REQUESTED, gift });
    const state = getState();

    try {
        const { data } = await xolaApi.post('/api/gifts', {
            ...gift,
            seller: { id: state.seller.id },
            items: gift.items.map(giftItem => {
                const item = _.omit(giftItem, ['id', 'coupon', 'recipient', 'adjustments', 'paymentMethod']);
                return {
                    ...item,
                    amount: getGiftItemAmount(state, item, false),
                };
            }),
            baseAmount: getGiftBaseAmount(state),
            amount: getGiftAmount(state),
            amountPaid: getPayableAmount(state),
            currency: getCurrency(state),
        });

        dispatch({ type: SAVE_GIFT_SUCCEEDED, gift: data });
        dispatch(paymentSuccess(data));

        // Wrapping in a try-catch block just to be safe
        // and to not interfere with a successful purchase flow.
        try {
            googleAnalyticsTracker.trackGift(new GoogleAnalyticsGift(data));
            facebookPixelTracker.onGiftPurchase(state.seller, data);
        } catch (error) {
            console.error(error);
        }

        return data;
    } catch (error) {
        dispatch({ type: SAVE_GIFT_FAILED });

        if (_.has(error, 'response.data.field') || _.has(error, 'response.data.global')) {
            dispatch(showError(formatResponseError(error.response.data)));
        } else {
            dispatch(showError('Failed to Complete the Purchase', error));
        }
    }
};

export const commitGift = () => async (dispatch, getState) => {
    try {
        dispatch({ type: COMMIT_GIFT_REQUESTED });
        const { gift, seller } = getState();
        const { apiKey } = gift.organizer;
        const response = await xolaApi.post(
            `/api/gifts/${gift.id}/commit?seller=${seller.id}`,
            { seller: seller.id },
            { headers: { 'X-API-KEY': apiKey } },
        );
        dispatch({
            type: COMMIT_GIFT_SUCCEEDED,
            gift: response.data,
        });
        navigate('/success');
    } catch (e) {
        dispatch({ type: COMMIT_GIFT_FAILED, error: e.message });
    }
};

export const releaseGift = () => async (dispatch, getState) => {
    try {
        dispatch({ type: RELEASE_GIFT_REQUESTED });
        const { gift, request, seller } = getState();
        const { apiKey } = gift.organizer;
        await xolaApi.post(
            `/api/gifts/${gift.id}/release?seller=${seller.id}`,
            { seller: seller.id },
            { headers: { 'X-API-KEY': apiKey } },
        );
        dispatch({
            type: RELEASE_GIFT_SUCCEEDED,
            gift: request,
        });
        dispatch(releaseSuccess());
    } catch (e) {
        dispatch({ type: RELEASE_GIFT_FAILED, error: e.message });
    }
};

export const PaymentMethod = {
    CREDIT_CARD: 'cc',
    CASH: 'cash',
    CHECK: 'check',
    OTHER: 'other',
    INTENT: 'intent',
};

export const PaymentIntentMethod = {
    CARD_PRESENT: 'card_present',
    CARD_NOT_PRESENT: 'card',
};

export const GiftItemStatus = {
    STATUS_HOLD: 103,
    STATUS_ACCEPTED: 200,
};

export const GiftSource = {
    OFFICE: 'office',
    CHECKOUT: 'checkout',
    GIFT: 'gift',
};
