import { InputGroup, InputGroupAddon, InputGroupText, Input, Button } from 'reactstrap';
import React, { Component, Fragment } from 'react';
import styles from './SpinnerGroup.module.scss';
import classNames from 'classnames';
import HoverTooltip from './HoverTooltip';

class Spinner extends Component {
    static defaultProps = {
        min: 0,
        max: Number.POSITIVE_INFINITY,
        value: 0,
    };

    handleIncrement = () => {
        const { value, max, onChange } = this.props;
        const nextValue = value + 1;

        if (nextValue <= max) {
            onChange(nextValue);
        }
    };

    handleDecrement = () => {
        const { value, min, onChange } = this.props;
        const nextValue = value - 1;

        if (nextValue >= min) {
            onChange(nextValue);
        }
    };

    render() {
        const { children, truncateLabel = false, min, max, value = 0, caption } = this.props;

        return (
            <Fragment>
                <InputGroup className="mt-2">
                    {children ? (
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText className={classNames('border', { [styles.label]: truncateLabel })}>
                                <HoverTooltip placement="left" content={children}>
                                    {children}
                                </HoverTooltip>
                            </InputGroupText>
                        </InputGroupAddon>
                    ) : null}

                    <Input
                        aria-label={`${caption} quantity`}
                        style={{ maxWidth: 50, minWidth: 50 }}
                        className="text-center border"
                        value={value}
                        onChange={() => null}
                    />

                    <InputGroupAddon addonType="append">
                        <Button
                            aria-label={`Decrease ${caption} quantity`}
                            type="button"
                            className="border"
                            onClick={this.handleDecrement}
                            disabled={value === min}
                            outline
                        >
                            <i className="fas fa-minus" />
                        </Button>
                    </InputGroupAddon>

                    <InputGroupAddon addonType="append">
                        <Button
                            aria-label={`Increase ${caption} quantity`}
                            type="button"
                            className="border"
                            onClick={this.handleIncrement}
                            disabled={value === max}
                            outline
                        >
                            <i className="fas fa-plus" />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
                <div>{caption ? <small className="text-muted">{caption}</small> : null}</div>
            </Fragment>
        );
    }
}

export default Spinner;
