import { removeUncompletedGiftItems } from '../actions/giftActions';
import Stepper, { Step } from '../components/Stepper';
import ProductList from '../components/ProductList';
import SellerLogo from '../components/SellerLogo';
import CartButton from '../components/CartButton';
import Header from '../components/Header';
import Errors from '../components/Errors';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from '../components/Page';

class ProductsPage extends Component {
    componentDidMount() {
        this.props.removeUncompletedGiftItems();
    }

    render() {
        return (
            <Page>
                <Errors />

                <Header title="Select a Gift" left={<SellerLogo />} right={<CartButton />}>
                    <Stepper>
                        <Step current>1</Step>
                        <Step>2</Step>
                        <Step>3</Step>
                    </Stepper>
                </Header>

                <ProductList />
            </Page>
        );
    }
}

const mapDispatchToProps = {
    removeUncompletedGiftItems,
};

export default connect(null, mapDispatchToProps)(ProductsPage);
