import { isConversionTrackingEnabled } from '../../selectors/sellerSelectors';
import { isSellerContext } from '../../selectors/bootstrapSelectors';
import CustomConversionSnippet from './CustomConversionSnippet';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

const rootElement = document.getElementById('cct-root');

class CustomConversionTracker extends Component {
    render() {
        const { gift, session, isEnabled } = this.props;

        if (rootElement && isEnabled && gift.id) {
            return ReactDOM.createPortal(<CustomConversionSnippet session={session} gift={gift} />, rootElement);
        }

        return null;
    }
}

const mapStateToProps = state => ({
    isEnabled: isConversionTrackingEnabled(state) && !isSellerContext(state),
    session: state.bootstrap.session,
    gift: state.gift,
});

export default connect(mapStateToProps)(CustomConversionTracker);
