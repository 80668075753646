import { FormGroup, Label, Col, Row, Button, CustomInput } from 'reactstrap';
import recipientSchema from '../schemas/recipientSchema';
import { Formik, Field, Form } from 'formik';
import ErrorMessage from './ErrorMessage';
import React, { Component } from 'react';
import { Link } from '@reach/router';
import FormField from './FormField';
import domId from '../utils/domId';

class RecipientForm extends Component {
    id = {
        name: domId(this, 'name'),
        email: domId(this, 'email'),
        message: domId(this, 'message'),
        sendToRecipient: domId(this, 'sendToRecipient'),
    };

    getInitialValues() {
        const { giftItem } = this.props;

        return {
            recipientName: giftItem.recipientName,
            recipientEmail: giftItem.recipientEmail,
            message: giftItem.message,
            sendToRecipient: giftItem.sendToRecipient,
        };
    }

    render() {
        const { onConfirm, disableSubmit, showBackButton } = this.props;

        return (
            <Formik initialValues={this.getInitialValues()} validationSchema={recipientSchema} onSubmit={onConfirm}>
                <Form>
                    <FormGroup row>
                        <Label for={this.id.name} sm={2}>
                            Name
                        </Label>

                        <Col sm={10}>
                            <FormField name="recipientName" id={this.id.name} placeholder="Recipient's full name" />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for={this.id.email} sm={2}>
                            Email
                        </Label>

                        <Col sm={10}>
                            <FormField
                                className="mb-2"
                                name="recipientEmail"
                                id={this.id.email}
                                placeholder="Recipient's email"
                            />
                            <Field
                                as={CustomInput}
                                type="checkbox"
                                name="sendToRecipient"
                                id={this.id.sendToRecipient}
                                label="Email gift certificate to recipient"
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for={this.id.message} sm={2}>
                            Message
                        </Label>

                        <Col sm={10}>
                            <Field
                                name="message"
                                className="form-control"
                                component="textarea"
                                rows={3}
                                id={this.id.message}
                                placeholder="Write a personal message"
                            />
                        </Col>
                    </FormGroup>

                    <Row className="mt-2 mb-5">
                        <Col sm={2} />

                        <Col>
                            <small className="text-danger">
                                <ErrorMessage name="sendToRecipient" />
                            </small>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={2} sm={2} xs={6}>
                            {showBackButton ? (
                                <Link
                                    aria-label="Back"
                                    to="/"
                                    className="btn btn-lg btn-outline-secondary btn-block py-3"
                                >
                                    <i className="fas fa-chevron-left" />
                                </Link>
                            ) : null}
                        </Col>

                        <Col lg={10} sm={10} xs={6}>
                            <Button
                                className="py-3"
                                type="submit"
                                size="lg"
                                color="primary"
                                disabled={disableSubmit}
                                block
                            >
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        );
    }
}

export default RecipientForm;
