import { Elements, StripeProvider as Provider } from 'react-stripe-elements';
import { getDefaultGateway, getPaymentTokenization } from '../selectors/sellerSelectors';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class StripeProvider extends Component {
    stripe = null;
    state = {};

    componentDidUpdate() {
        const { defaultGateway } = this.props;

        if (!this.stripe && defaultGateway.publicKey) {
            this.stripe = window.Stripe(defaultGateway.publicKey);
            this.setState({});
        }
    }

    render() {
        return (
            <Provider stripe={this.stripe}>
                <Elements>{this.props.children}</Elements>
            </Provider>
        );
    }
}

const mapStateToProps = state => ({
    paymentTokenization: getPaymentTokenization(state.seller),
    defaultGateway: getDefaultGateway(state.seller),
});

export default connect(mapStateToProps)(StripeProvider);
