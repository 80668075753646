import styles from './FixedProgressBar.module.scss';
import { NProgress } from '@tanem/react-nprogress';
import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';

class FixedProgressBar extends Component {
    render() {
        const { progress, height = 3 } = this.props;

        return (
            <NProgress isAnimating={progress.total > progress.done}>
                {({ isFinished, progress }) => (
                    <div className="fixed-top">
                        <Progress
                            className={classNames('rounded-0', { [styles.hide]: isFinished })}
                            style={{ height }}
                            value={progress * 100}
                        />
                    </div>
                )}
            </NProgress>
        );
    }
}

const mapStateToProps = state => ({
    progress: state.progress,
});

export default connect(mapStateToProps)(FixedProgressBar);
