import isMobileApp from './isMobileApp';
import socket from '../socket';
import _ from 'lodash';

/**
 * This class implements methods that are required for the mobile swiper to function.
 */
class CardSwipeListener {
    CREDIT_CARD_START_SENTINEL = '%B';

    constructor(options) {
        this.options = {
            isTokenizationEnabled: false,
            onError: console.warn,
            onSwipe: console.log,
            ...options,
        };
    }

    startCardReader() {
        socket.postMessage('startCardReader');
    }

    stopCardReader() {
        socket.postMessage('stopCardReader');
    }

    enable() {
        this.disable();
        window.addEventListener('keypress', this.handleKeypress, true);

        if (isMobileApp()) {
            // Let the app know that it should start listening for card swipes.
            this.startCardReader();

            // Card reader successfully read a card swipe.
            window.readCreditCard = (number, name, expiry) => {
                // Set card data into the payment form.
                this.bindSwipe(number, expiry, name);

                // Start listening again (in case a new card is used).
                this.startCardReader();
            };

            socket.on('readCreditCard', data => {
                window.readCreditCard(data.number, data.name, data.exp);
            });

            window.externalCardSwipe = raw => {
                console.log('Received encrypted raw data from swiper');
                this.parseCardSwipe(raw);
            };

            socket.on('externalCardSwipe', data => {
                // Received data through easyXDM call
                this.parseCardSwipe(data.raw);
            });

            // Card reader failed
            window.readCreditCardError = message => {
                if (message === 'Waiting for swipe timed out. Please try again.') {
                    // Reader timed out, so start listening again
                    this.startCardReader();
                }

                const ignore = [
                    'Credit card reader is not ready. Please try again later.',
                    'Track1 is missed in the credit card.',
                    'Swiping is already initiated. The command is skipped.',
                ];

                if (!_.includes(ignore, message)) {
                    // Error message is not in the list of ignored strings, so show it.
                    // Alerts look nice natively on tablets, so go with it.
                    this.options.onError(message);
                }
            };

            socket.on('readCreditCardError', window.readCreditCardError);
        }
    }

    disable() {
        window.removeEventListener('keypress', this.handleKeypress, true);

        socket.off('readCreditCard');
        socket.off('externalCardSwipe');
        socket.off('readCreditCardError');

        if (isMobileApp()) {
            // Let the app know that it should stop listening for card swipes.
            this.stopCardReader();

            // Remove all listener functions.
            delete window.readCreditCard;
            delete window.readCreditCardError;
        }
    }

    /**
     * Called when any keystroke is detected.
     * This function's job is to identify if the keystroke is indeed part of
     * a card swipe and capture it if it is. This is usually for USB based card swipe devices.
     */
    handleKeypress = e => {
        const ch = String.fromCharCode(e.which);

        if ((ch === '%' || ch === ';') && !this.cardSwiping) {
            // Initialize/reset card data variables.
            this.cardSwipeEvents = [];
            this.cardSwipeData = '';
            this.cardSwiping = true;

            if (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') {
                this.lastFocusedElement = window.document.activeElement;
                window.document.activeElement.blur();
            }
        }

        if (this.cardSwiping) {
            // Prevent the card swipe data from showing up on any input field.
            e.preventDefault();
            e.stopImmediatePropagation();

            // Save the keystroke into the card swipe stack.
            this.cardSwipeEvents.push(e);
            this.cardSwipeData += ch;

            if (
                e.which === 13 ||
                (this.cardSwipeData.length === 2 && this.cardSwipeData !== this.CREDIT_CARD_START_SENTINEL)
            ) {
                // Found an enter key OR the first two chars are not the
                // start sentinel (user could have type % manually).
                this.completeCardSwipe();
            }
        }
    };

    completeCardSwipe() {
        if (this.lastFocusedElement) {
            this.lastFocusedElement.focus();
        }

        this.cardSwiping = false;
        this.parseCardSwipe(this.cardSwipeData);
        this.cardSwipeData = '';
    }

    /**
     * Called when the card's track data has been completely captured.
     *
     * @param {string} rawParam Track data
     */
    parseCardSwipe(rawParam) {
        let raw = rawParam;
        let encrypted = null;
        const split = raw.indexOf('|');

        if (split !== -1) {
            // Encrypted card swipe.
            encrypted = raw;
            raw = raw.substring(0, split);
        }

        // Derived pattern from https://en.wikipedia.org/wiki/ISO/IEC_7813
        const patterns = [
            /%B(\d+)\^(.*?)\^(\^|\d{4})(\^|\d{3})(.*?)\?/, // Track 1
            /%B(\d+)&(.*?)&(&|\d{4})(&|\d{3})(.*?)\?/, // Track 1
            /;(\d{1,19})(\s)?=(=|\d{4})(=|\d{3})(.*?)\?/, // Track 2
        ];

        let matches, number, expiry, i, name;

        for (i = 0; i < patterns.length; i += 1) {
            matches = patterns[i].exec(raw);

            if (matches) {
                // We have a regex match.
                number = matches[1];
                name = matches[2];
                expiry = matches[3];

                // When we find a proper number, break out of this loop.
                if (number && expiry) break;
            }
        }

        if (!number || !expiry) {
            if (raw.length < 10) {
                // This was probably a % key press, so replay the data on focused field
                if (this.lastFocusedElement) {
                    this.lastFocusedElement.value += raw;
                }
            } else {
                throw Error('Unable to read card data. Please try again.');
            }

            return;
        }

        if (encrypted) {
            number = number.substr(0, 4) + '********'.substr(0, number.length - 8) + number.substr(-4, 4);

            if (!this.options.isTokenizationEnabled) {
                const message =
                    'You are using an encrypted card reader. This requires the tokenization ' +
                    'feature to be enabled for your account. Please contact Xola Support to request access to ' +
                    'this feature.';

                return this.options.onError(message);
            }
        }

        this.bindSwipe(number, expiry, name, raw, encrypted);
    }

    /**
     * Reformat the name field from "SMITH/JOHN" to "JOHN SMITH".
     *
     * @param {string} name
     * @returns {string}
     */
    formatName(name) {
        return _.trim(name)
            .split('/')
            .reverse()
            .join(' ')
            .trim();
    }

    /**
     * Bind swipe information to the payment model in the order
     *
     * @param {String} number      Credit card number
     * @param {String} exp         Expiration date (YYMM)
     * @param {String} cardName    Name of the card holder
     * @param {String} [raw]       Raw track data
     * @param {string} [encrypted] Encrypted swipe data
     */
    bindSwipe(number, exp, cardName, raw, encrypted) {
        const expMonth = parseInt(exp.substr(2, 2), 10);
        const expYear = `20${exp.substr(0, 2)}`;

        const card = {
            billingName: this.formatName(cardName),
            number,
            expiryMonth: expMonth.toString(),
            expiryYear: expYear,
            swipe: true,
        };

        if (!_.isEmpty(raw)) {
            card.tracks = _.trim(raw);
        }

        if (encrypted) {
            card.encrypted = encrypted;
        }

        this.options.onSwipe(card);
    }
}

export default CardSwipeListener;
