import styles from './CustomConversionSnippet.module.scss';
import xolaUrl from '../../utils/xolaUrl';
import React, { Component } from 'react';
import queryString from 'query-string';

class CustomConversionSnippet extends Component {
    getUrl() {
        const { gift, session } = this.props;
        const params = { apiKey: gift.organizer.apiKey };

        if (session) {
            params.session = session;
        }

        return xolaUrl(`/api/orders/${gift.id}/snippets?` + queryString.stringify(params));
    }

    render() {
        const { gift } = this.props;
        return <iframe className={styles.main} id={gift.id} title={gift.id} src={this.getUrl()} />;
    }
}

export default CustomConversionSnippet;
