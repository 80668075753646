import { commitGift } from './giftActions';

export const PAYMENT_FAILED = 'PAYMENT_FAILED';

export const COLLECT_PAYMENT_FAILED = 'COLLECT_PAYMENT_FAILED';
export const COLLECT_PAYMENT_SUCCEEDED = 'COLLECT_PAYMENT_SUCCEEDED';

export const SET_PAYMENT_DEVICE_ID = 'SET_PAYMENT_DEVICE_ID';

export const collectPaymentSucceeded = gift => async dispatch => {
    dispatch({ type: COLLECT_PAYMENT_SUCCEEDED, gift });
    dispatch(commitGift());
};
export const collectPaymentFailed = (gift, errorMessage) => ({
    type: COLLECT_PAYMENT_FAILED,
    gift,
    errorMessage,
});
export const addDevice = deviceId => ({ type: SET_PAYMENT_DEVICE_ID, deviceId });
