import { BOOTSTRAP, BootstrapContext, UPDATE_BOOTSTRAP_LOCALE } from '../actions/bootstrapActions';
import { FETCH_BUTTON_SUCCEEDED } from '../actions/buttonActions';
import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

export const initialState = {
    context: BootstrapContext.BUTTON, // Context.
    iframe: false, // Is app loaded in iframe?
    seller: null, // Seller ID.
    button: null, // Button ID.
    session: null, // Custom Conversion Tracker session.
    gaCode: null, // Google Analytics code.
    gaClient: null, // Google Analytics client ID.
    gaPage: null, // Google Analytics source page.
    xdm: false, // Flag for using easyXDM.
    gift: null, // Physical gift code.
    value: null, // Physical gift value.
    locale: null,
};

const bootstrapReducer = createReducer(initialState, {
    [BOOTSTRAP](state, action) {
        const bootstrap = _.pick(action, [
            'context',
            'iframe',
            'seller',
            'button',
            'session',
            'gaCode',
            'gaClient',
            'gaPage',
            'xdm',
            'gift',
            'value',
        ]);

        _.assign(state, bootstrap);

        if (action.locale && action.locale !== 'auto') {
            state.locale = action.locale;
        }
    },

    [FETCH_BUTTON_SUCCEEDED](state, { button }) {
        state.seller = button.seller.id;
    },

    [UPDATE_BOOTSTRAP_LOCALE](state, { locale }) {
        state.locale = locale;
    },
});

export default bootstrapReducer;
