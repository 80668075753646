import ResizeObserver from 'resize-observer-polyfill';
import socket from '../socket';

const resizeObserver = {
    observe(element) {
        const resizeObserver = new ResizeObserver(() => {
            socket.postMessage('setFrameHeight', element.scrollHeight);
        });

        resizeObserver.observe(element);
    },
};

export default resizeObserver;
