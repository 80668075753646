import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from './Spinner';

class SpinnerGroup extends Component {
    static defaultProps = {
        min: 0,
        max: Number.POSITIVE_INFINITY,
        value: 0,
    };

    handleDemographicChange = (demographic, product, nextValue) => {
        const { onChange } = this.props;
        onChange(demographic, product, nextValue);
    };

    render() {
        const { giftItem, product } = this.props;

        return (
            <div className="spinner-group">
                {giftItem.demographics.map(demographic => {
                    return (
                        <Spinner
                            key={demographic.id}
                            children={demographic.label}
                            value={demographic.quantity}
                            min={giftItem.quantity > 1 ? 0 : product.group.outingMin}
                            max={product.group.outingMax === giftItem.quantity ? 0 : product.group.outingMax}
                            truncateLabel
                            onChange={nextValue => this.handleDemographicChange(demographic, product, nextValue)}
                            caption={demographic.caption}
                        />
                    );
                })}
            </div>
        );
    }
}
const mapStateToProps = (state, props) => ({
    giftItem: state.gift.items[props.giftItemIndex],
});

export default connect(mapStateToProps)(SpinnerGroup);
