import { PriceSchemePriceType, getPriceTypeConstraint, getPriceScheme } from '../../selectors/experienceSelector';
import { getDiscountAmount, getProductDiscountAmount } from '../../selectors/productsSelector';
import { getProductFeeAmount } from '../../selectors/feeSelector';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breakdown from '../Breakdown';
import Currency from '../Currency';
import _ from 'lodash';

class ProductItemBreakdown extends Component {
    getProductName() {
        const { product, quantity = 1, experience } = this.props;

        if (product && experience) {
            const constraint = getPriceTypeConstraint(experience);

            if (constraint.priceType === PriceSchemePriceType.PERSON) {
                const { price } = getPriceScheme(experience, quantity);

                return (
                    <span>
                        <Currency>{price}</Currency> × {quantity}
                    </span>
                );
            }

            return (
                <span>
                    Up to <var> {experience.group.outingMax} </var> guests
                </span>
            );
        }

        if (product.name) {
            return <var>{product.name}</var>;
        }

        return 'Gift Amount';
    }

    getDemographicLineItem = demographic => {
        const { experience } = this.props;
        const constraint = getPriceTypeConstraint(experience);

        if (constraint.priceType === PriceSchemePriceType.PERSON) {
            const priceScheme = getPriceScheme(experience, demographic.quantity);
            const demographicPrice = demographic.discount
                ? priceScheme.price - getDiscountAmount(priceScheme.price, demographic.discount)
                : priceScheme.price;

            return (
                <span>
                    {demographic.label} (<Currency>{demographicPrice}</Currency> × {demographic.quantity})
                </span>
            );
        }

        return (
            <span>
                {demographic.label} × <var>{demographic.quantity}</var>
            </span>
        );
    };

    getDemographicLineItems = demographics => {
        const { experience } = this.props;
        const constraint = getPriceTypeConstraint(experience);

        return demographics
            .filter(demographic => demographic.quantity > 0)
            .map(demographic => {
                const priceScheme = getPriceScheme(experience, demographic.quantity);
                const demographicPrice = demographic.discount
                    ? priceScheme.price - getDiscountAmount(priceScheme.price, demographic.discount)
                    : priceScheme.price;
                return {
                    title: this.getDemographicLineItem(demographic),
                    value:
                        constraint.priceType === PriceSchemePriceType.PERSON
                            ? demographicPrice * demographic.quantity
                            : null,
                };
            });
    };

    getItems() {
        const { baseAmount = 0, discountAmount = 0, feeAmount, demographics, isCustomAmountBreakdown } = this.props;
        const experience = this.props.product.experience ?? this.props.experience;

        let lineItems = [];
        if (!_.isEmpty(demographics) && !_.isUndefined(experience)) {
            const constraint = getPriceTypeConstraint(experience);
            if (constraint.priceType === PriceSchemePriceType.OUTING) {
                lineItems.push({
                    title: (
                        <span>
                            Up to <var> {experience.group.outingMax} </var> guests
                        </span>
                    ),
                    value: baseAmount,
                });
            }

            lineItems = _.concat(lineItems, this.getDemographicLineItems(demographics));
        } else {
            lineItems = [
                { title: this.getProductName(), value: baseAmount },
                { title: 'Discount', value: -discountAmount },
            ];
        }

        if (feeAmount > 0) {
            if (isCustomAmountBreakdown) {
                lineItems.push({
                    title: 'Prepaid Fees',
                    value: feeAmount,
                    tooltip: 'Prepaid Fees covers the convenience fee when making a new booking',
                });
            } else {
                lineItems.push({ title: 'Prepaid Taxes & Fees', value: feeAmount });
            }
        }

        return lineItems;
    }

    render() {
        return <Breakdown>{this.getItems()}</Breakdown>;
    }
}

const mapStateToProps = (state, props) => {
    const orderAmountIncludesPartnerFee = !!_.find(state.seller.partnerFeeFormulas, {
        orderAmountIncludesPartnerFee: true,
    });
    const partnerFeePaidByTraveler = !!_.find(state.seller.partnerFeeFormulas, {
        source: 'gift',
        type: 'traveler',
    });
    var includePartnerFee = orderAmountIncludesPartnerFee || partnerFeePaidByTraveler;

    const experience = props.product.experience;

    if (experience) {
        const constraint = getPriceTypeConstraint(experience);
        if (constraint && constraint.priceType !== PriceSchemePriceType.OUTING) {
            const giftAmount = _.sumBy(props.demographics, demographic => {
                if (demographic.quantity > 0) {
                    const priceScheme = getPriceScheme(experience, demographic.quantity);
                    console.log({ constraint }, { priceScheme });
                    const demographicPrice = demographic.discount
                        ? priceScheme.price - getDiscountAmount(priceScheme.price, demographic.discount)
                        : priceScheme.price;
                    return constraint.priceType === PriceSchemePriceType.PERSON
                        ? demographicPrice * demographic.quantity
                        : demographicPrice;
                }
            });
            const discountAmount = getProductDiscountAmount(props.product, giftAmount);

            return {
                feeAmount: getProductFeeAmount(state, props.product, giftAmount, includePartnerFee, props.quantity),
                discountAmount,
                experience,
            };
        }
    }

    const discountAmount = getProductDiscountAmount(props.product, props.baseAmount);
    const feeBaseAmount = props.baseAmount - discountAmount;

    return {
        feeAmount: getProductFeeAmount(state, props.product, feeBaseAmount, includePartnerFee, props.quantity),
        discountAmount,
        experience,
    };
};

export default connect(mapStateToProps)(ProductItemBreakdown);
