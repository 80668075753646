import { scrollUp } from './socketActions';
import logger from '../logger';
import produce from 'immer';
import _ from 'lodash';

export const DISMISS_LATEST_ERROR = 'DISMISS_LATEST_ERROR';
export const SHOW_ERROR = 'SHOW_ERROR';

const createError = (title, error) => {
    if (error && error.response) {
        return {
            title,
            message: `Response: ${error.response.status} ${error.response.statusText}`,
            details: error.response.status < 500 ? error.response.data : null,
        };
    }

    return { title, message: error ? error.message : null };
};

export const dismissLatestError = () => ({
    type: DISMISS_LATEST_ERROR,
});

export const showError = (title, error) => (dispatch, getState) => {
    if (error) {
        const log = { message: title };

        // Unset the API Key when logging the error.
        // With immer we can remove it without changing the original error object.
        log.error = produce(error, error => {
            _.unset(error, 'config.headers.X-API-KEY');
        });

        // Unset the API Key and CC when logging the application state.
        log.state = produce(getState(), state => {
            _.unset(state, 'user.apiKey');
            _.unset(state, 'gift.payment.card');
        });

        logger.log(log);
    }

    dispatch({ type: SHOW_ERROR, error: createError(title, error) });
    dispatch(scrollUp());
};
