import { Button } from 'reactstrap';
import React from 'react';

const LoadingButton = ({ loading, disabled, children, ...rest }) => {
    return (
        <Button disabled={loading || disabled} {...rest}>
            {loading ? <i className="fas fa-circle-notch fa-spin text-white" /> : children}
        </Button>
    );
};

export default LoadingButton;
