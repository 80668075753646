import { createReducer } from 'redux-starter-kit';
import { CANCEL_PAYMENT_COLLECTION, INIT_PAYMENT_REQUESTED } from '../actions/socketActions';
import {
    COMMIT_GIFT_FAILED,
    COMMIT_GIFT_SUCCEEDED,
    RELEASE_GIFT_FAILED,
    RELEASE_GIFT_SUCCEEDED,
    SAVE_GIFT_SUCCEEDED,
} from '../actions/giftActions';
import { COLLECT_PAYMENT_FAILED, COLLECT_PAYMENT_SUCCEEDED, SET_PAYMENT_DEVICE_ID } from '../actions/paymentActions';
import { FETCH_SELLER_SUCCEEDED } from '../actions/sellerActions';

export const initialState = {
    deviceId: null,
    device: null,
    intent: null,
    isError: false,
    isConfirming: false,
    isCollecting: false,
    errorMessage: null,
};

const paymentReducer = createReducer(initialState, {
    [SET_PAYMENT_DEVICE_ID](state, { deviceId }) {
        state.deviceId = deviceId;
    },
    [FETCH_SELLER_SUCCEEDED](state, { seller }) {
        state.device =
            seller.preferences.stripeTerminal &&
            seller.preferences.stripeTerminal.paymentDevices.find(device => device.id === state.deviceId);
    },
    [SAVE_GIFT_SUCCEEDED](state, { gift }) {
        state.intent = gift.paymentIntents ? gift.paymentIntents[0] : null;
    },
    [COLLECT_PAYMENT_FAILED](state, { errorMessage }) {
        state.isError = true;
        state.errorMessage = errorMessage;
    },
    [COLLECT_PAYMENT_SUCCEEDED](state) {
        state.isConfirming = true;
    },
    [INIT_PAYMENT_REQUESTED](state) {
        state.isCollecting = true;
    },
    [CANCEL_PAYMENT_COLLECTION](state) {
        state.isError = false;
        state.errorMessage = null;
    },
    [COMMIT_GIFT_SUCCEEDED](state) {
        state.isCollecting = false;
        state.isConfirming = false;
        state.intent = null;
    },
    [COMMIT_GIFT_FAILED](state) {
        state.isCollecting = false;
        state.isConfirming = false;
        state.intent = null;
    },
    [RELEASE_GIFT_SUCCEEDED](state) {
        state.isCollecting = false;
        state.isConfirming = false;
        state.intent = null;
    },
    [RELEASE_GIFT_FAILED](state) {
        state.isCollecting = false;
        state.isConfirming = false;
        state.intent = null;
    },
});

export default paymentReducer;
