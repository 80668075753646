import React from 'react';

const GiftImage = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" fill="none" viewBox="0 0 512 512" {...props}>
            <path fill="#fff" d="M0 0H512V512H0z" />
            <g clipPath="url(#clip0)">
                <mask id="a" width="322" height="321" x="95" y="96" maskUnits="userSpaceOnUse">
                    <path
                        fill="#fff"
                        d="M360.754 152.871c0 15.556-5.235 28.383-14.521 38.746l54.689-.017c8.88 0 16.078 7.261 16.078 16.169v64.262c0 8.93-7.172 16.169-16.107 16.169l-16.093-.001v112.775c0 8.851-7.159 16.026-16.114 16.026H143.314c-8.899 0-16.114-7.231-16.114-16.026V288.199l-16.03.001c-8.93 0-16.17-7.261-16.17-16.169v-64.262c0-8.93 7.177-16.169 16.078-16.169l55.152.009c-9.282-10.361-14.514-23.186-14.514-38.738 0-35.887 28.559-58.925 61.958-55.88 17.178 1.567 32.1 9.58 42.564 22.388 10.458-12.808 25.38-20.821 42.558-22.388 33.399-3.045 61.958 19.993 61.958 55.88zM272.1 223.799h-32.2v160.92h32.2v-160.92zm80.5 64.4h-48.3v96.52l48.3.007v-96.527zm-144.9 0h-48.3v96.527l48.3-.007v-96.52zM384.8 223.8l-80.5-.001v32.2l80.5.001v-32.2zm-177.1-.001l-80.5.001V256l80.5-.001v-32.2zm93.815-94.741c-17.022 1.552-29.424 15.438-29.151 39.541-.106 9.035-.264 22.857-.264 22.857s14.317-2.85 22.941-5.751c21.863-7.356 33.309-18.072 33.309-32.834 0-15.982-11.447-25.216-26.835-23.813zm-117.599 23.813c0 14.762 11.445 25.478 33.309 32.834 8.29 2.789 21.842 5.531 22.877 5.739.012-.911.023-1.863.034-2.844-.055-4.523-.158-13.501-.235-20.001.273-24.103-12.129-37.989-29.151-39.541-15.387-1.403-26.834 7.831-26.834 23.813z"
                    />
                </mask>
                <g mask="url(#a)">
                    <path
                        fill="#4D4D4D"
                        d="M360.754 152.871c0 15.556-5.235 28.383-14.521 38.746l54.689-.017c8.88 0 16.078 7.261 16.078 16.169v64.262c0 8.93-7.172 16.169-16.107 16.169l-16.093-.001v112.775c0 8.851-7.159 16.026-16.114 16.026H143.314c-8.899 0-16.114-7.231-16.114-16.026V288.199l-16.03.001c-8.93 0-16.17-7.261-16.17-16.169v-64.262c0-8.93 7.177-16.169 16.078-16.169l55.152.009c-9.282-10.361-14.514-23.186-14.514-38.738 0-35.887 28.559-58.925 61.958-55.88 17.178 1.567 32.1 9.58 42.564 22.388 10.458-12.808 25.38-20.821 42.558-22.388 33.399-3.045 61.958 19.993 61.958 55.88zM272.1 223.799h-32.2v160.92h32.2v-160.92zm80.5 64.4h-48.3v96.52l48.3.007v-96.527zm-144.9 0h-48.3v96.527l48.3-.007v-96.52zM384.8 223.8l-80.5-.001v32.2l80.5.001v-32.2zm-177.1-.001l-80.5.001V256l80.5-.001v-32.2zm93.815-94.741c-17.022 1.552-29.424 15.438-29.151 39.541-.106 9.035-.264 22.857-.264 22.857s14.317-2.85 22.941-5.751c21.863-7.356 33.309-18.072 33.309-32.834 0-15.982-11.447-25.216-26.835-23.813zm-117.599 23.813c0 14.762 11.445 25.478 33.309 32.834 8.29 2.789 21.842 5.531 22.877 5.739.012-.911.023-1.863.034-2.844-.055-4.523-.158-13.501-.235-20.001.273-24.103-12.129-37.989-29.151-39.541-15.387-1.403-26.834 7.831-26.834 23.813z"
                    />
                    <mask id="b" width="388" height="388" x="62" y="62" maskUnits="userSpaceOnUse">
                        <path fill="#fff" d="M449.2 62.8H62.8v386.4h386.4V62.8z" />
                    </mask>
                    <g mask="url(#b)">
                        <path fill="var(--primary)" d="M449.2 62.8H62.8v386.4h386.4V62.8z" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H322V322H0z" transform="translate(95 95)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default GiftImage;
