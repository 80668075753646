import StripeProvider from './StripeProvider';
import React, { Component } from 'react';
import ErrorLogger from './ErrorLogger';
import { Provider } from 'react-redux';
import App from './App';

class Root extends Component {
    render() {
        return (
            <Provider store={this.props.store}>
                <ErrorLogger>
                    <StripeProvider>
                        <App />
                    </StripeProvider>
                </ErrorLogger>
            </Provider>
        );
    }
}

export default Root;
