import { GiftAmountProduct, ProductObject } from '../actions/productActions';
import { isGiftOfferWithinPurchaseRestrictions } from './giftOfferSelector';
import { round } from 'mathjs';
import _ from 'lodash';
import { getPriceScheme } from './experienceSelector';
import { getGiftOfferByExperience } from './giftSelector';

export const getVisibleProducts = state => {
    const { button, products } = state;

    if (button.data.id) {
        return button.data.items
            .map(item => (item.product ? products.data[item.product.id] : GiftAmountProduct))
            .filter(product => {
                if (product.object === ProductObject.GIFT_OFFER) {
                    return isGiftOfferWithinPurchaseRestrictions(state, product.id);
                }

                return true;
            });
    }

    return _.values(products.data);
};

export const getDiscountAmount = (price, discount) => {
    if (discount) {
        switch (discount.amountType) {
            case DiscountAmountType.ABSOLUTE:
                return price > discount.amount ? discount.amount : price;
            case DiscountAmountType.PERCENT:
                return round(price * (discount.amount / 100), 2);
            case DiscountAmountType.OVERRIDE:
                return price - discount.amount;
            default:
                return price;
        }
    }
    return price;
};

export const getProductDiscountAmount = (product, baseAmount) => {
    if (product.system) {
        return 0;
    } else {
        return getDiscountAmount(baseAmount, product.discount);
    }
};

export const getExperienceDemographics = (experience, quantity) => {
    const priceScheme = getPriceScheme(experience, quantity);

    return experience.demographics
        .map(demographic => {
            return {
                ...demographic,
                quantity: 0,
                price: demographic.discount
                    ? priceScheme.price - getDiscountAmount(priceScheme.price, demographic.discount)
                    : priceScheme.price,
            };
        })
        .filter(demographic => demographic.price > 0);
};

export const getGiftItemProduct = (state, giftItem) => {
    if (giftItem.product && giftItem.product.id) {
        const experience = state.products.experiences[giftItem.product.id];
        return experience ? getGiftOfferByExperience(state, experience) : state.products.data[giftItem.product.id];
    }

    return GiftAmountProduct;
};

export const DiscountAmountType = {
    ABSOLUTE: 'absolute',
    PERCENT: 'percent',
    OVERRIDE: 'override',
};
