import { isSellerContext } from '../selectors/bootstrapSelectors';
import { getSellerLogoUrl } from '../selectors/sellerSelectors';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const LOGO_SIZE = 64;

class SellerLogo extends Component {
    render() {
        const { hideLogo, name, logoUrl } = this.props;

        if (hideLogo) {
            return null;
        }

        return (
            <div className="d-inline-flex align-items-center" notranslate="">
                <div style={{ height: LOGO_SIZE }} className="mr-3">
                    {logoUrl ? <img style={{ maxHeight: LOGO_SIZE }} src={logoUrl} alt={name} /> : null}
                </div>

                <strong className="text-uppercase">{name}</strong>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    name: state.seller.name,
    logoUrl: getSellerLogoUrl(state),
    hideLogo: isSellerContext(state),
});

export default connect(mapStateToProps)(SellerLogo);
