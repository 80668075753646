import { getIn } from 'formik';
import _ from 'lodash';

/**
 * Get the field error from form object.
 * @param {object} form Formik form.
 * @param {Field|string} field Formik field or field name.
 */
const getFormError = (form, field) => {
    const name = _.isString(field) ? field : field.name;
    const error = getIn(form.errors, name, null);
    const isTouched = getIn(form.touched, name);
    const isSubmitted = form.submitCount > 0;
    return (isTouched || isSubmitted) && error ? error : null;
};

export default getFormError;
