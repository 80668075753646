import {
    getPaymentTokenization,
    getOtherPaymentMethods,
    canUseEMVPayment,
    getDefaultGateway,
} from '../../selectors/sellerSelectors';
import { PaymentMethod, changePaymentMethod } from '../../actions/giftActions';
import PaymentMethodSelector from './PaymentMethodSelector';
import React, { Component, Fragment } from 'react';
import CreditCardForm from './CreditCardForm';
import { connect } from 'react-redux';
import { Field } from 'formik';
import chipImage from '../../images/chip.png';

class PaymentInput extends Component {
    render() {
        const { user, paymentMethods, canUseEMVPayment, payment } = this.props;

        return (
            <Field>
                {({ form }) => (
                    <Fragment>
                        {user ? (
                            <PaymentMethodSelector
                                className="mb-3"
                                method={form.values.payment.method}
                                onChange={method => {
                                    form.setFieldValue('payment.method', method);
                                    this.props.changePaymentMethod(method);
                                }}
                                paymentMethods={paymentMethods}
                                canUseEMVPayment={canUseEMVPayment}
                            />
                        ) : null}
                        {canUseEMVPayment && form.values.payment.method === PaymentMethod.INTENT ? (
                            <div className="p-3 d-inline-block text-center">
                                Credit card will be processed on {payment.device.label}{' '}
                                <img alt="chip" src={chipImage} />
                            </div>
                        ) : null}
                        {form.values.payment.method === PaymentMethod.CREDIT_CARD ? (
                            <CreditCardForm useStripeElements={true} />
                        ) : null}
                    </Fragment>
                )}
            </Field>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    payment: state.payment,
    paymentTokenization: getPaymentTokenization(state.seller),
    defaultGateway: getDefaultGateway(state.seller),
    paymentMethods: getOtherPaymentMethods(state.seller),
    canUseEMVPayment: canUseEMVPayment(state),
});

const mapDispatchToProps = {
    changePaymentMethod,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInput);
