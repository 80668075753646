import CouponScheduleSummary from './CouponScheduleSummary';
import CouponRestriction from './CouponRestriction';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

class CouponScheduleRestriction extends Component {
    render() {
        const { title, schedule, timeZone } = this.props;

        if (!schedule || _.size(schedule) === 0) {
            return null;
        }

        const schedules = _.isArray(schedule) ? schedule : [schedule];

        return (
            <CouponRestriction title={title}>
                {schedules.map((schedule, index) => (
                    <CouponScheduleSummary key={index} schedule={schedule} timeZone={timeZone} />
                ))}
            </CouponRestriction>
        );
    }
}

const mapStateToProps = state => ({
    timeZone: state.seller.timezoneName,
});

export default connect(mapStateToProps)(CouponScheduleRestriction);
