import getUserLocale from 'get-user-locale';
import { round } from 'mathjs';
import _ from 'lodash';

// As of 10 Dec, 2024
const EXCHANGE_RATES = {
    'AED': 3.673,
    'AUD': 1.54,
    'CAD': 1.36,
    'EUR': 0.92,
    'GBP': 0.79,
    'JPY': 149.87,
    'MXN': 20.39,
    'NZD': 1.67,
    'SEK': 10.93,
    'USD': 1
};

const userLocale = getUserLocale();

export const format = (amount, currency, locale = userLocale) => {
    const style = currency ? 'currency' : 'decimal';
    return new Intl.NumberFormat(locale, { style, currency }).format(amount);
};

export const getSymbol = (currency, locale = userLocale) => {
    const style = currency ? 'currency' : 'decimal';

    return (0)
        .toLocaleString(locale, { style, currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
        .replace(/\d/g, '')
        .trim();
};

export const roundCurrency = (num, currency) => {
    num = Number(num);

    if (isZeroDecimal(currency)) {
        return round(num);
    } else {
        if (round(num, 3) === round(num, 4)) {
            num = round(num, 3);
        }
        return round(num, 2);
    }
};

export const isZeroDecimal = currency => {
    var zeroDecimalCurrencies = ['JPY', 'CLP', 'KRW', 'LAK', 'PYG', 'VND', 'VUV'];
    return _.includes(zeroDecimalCurrencies, currency);
};

/**
 * Converts an amount in USD to a specified currency.
 *
 * @param {number} amountInUSD - The amount in USD.
 * @param {string} currency - The target currency code (e.g., 'EUR').
 * @returns {number} - The converted amount.
 * @throws {Error} - If the currency is unsupported.
 */
export const convertToCurrency = (amountInUSD, currency) => {
    const rate = EXCHANGE_RATES[currency];
    if (!rate) {
        throw new Error('Unsupported currency');
    }
    return amountInUSD * rate;
};
