import socket from '../socket';

// Incoming socket actions.
export const READ_CREDIT_CARD = 'readCreditCard';
export const EXTERNAL_CARD_SWIPE = 'externalCardSwipe';
export const READ_CREDIT_CARD_ERROR = 'readCreditCardError';
export const ADD_PHYSICAL_GIFT_ITEM = 'addPhysicalGiftItem';

// Internal socket actions.
export const CLOSE_WINDOW = 'CLOSE_WINDOW';
export const START_CARD_READER = 'START_CARD_READER';
export const STOP_CARD_READER = 'STOP_CARD_READER';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SCROLL_UP = 'SCROLL_UP';
export const INIT_PAYMENT_REQUESTED = 'INIT_PAYMENT_REQUESTED';
export const CANCEL_PAYMENT_COLLECTION = 'CANCEL_PAYMENT_COLLECTION';

export const closeWindow = () => {
    socket.postMessageRaw('close');
    return { type: CLOSE_WINDOW };
};

export const startCardReader = () => {
    socket.postMessage('startCardReader');
    return { type: START_CARD_READER };
};

export const stopCardReader = () => {
    socket.postMessage('stopCardReader');
    return { type: STOP_CARD_READER };
};

export const paymentSuccess = gift => {
    socket.postMessage('paymentSuccess', { gift });
    return { type: PAYMENT_SUCCESS, gift };
};

export const showLoader = () => {
    socket.postMessage('showLoader');
    return { type: SHOW_LOADER };
};

export const hideLoader = () => {
    socket.postMessage('hideLoader');
    return { type: HIDE_LOADER };
};

export const scrollUp = () => {
    window.scrollTo(0, 0);
    socket.postMessage('scrollUp');
    return { type: SCROLL_UP };
};

export const createPaymentIntent = (clientSecret, gift, paymentIntent) => {
    socket.postMessage('createPaymentIntent', { clientSecret, gift, paymentIntent });
    return { type: INIT_PAYMENT_REQUESTED };
};

export const cancelPaymentCollection = (gift, paymentIntent) => {
    socket.postMessage('cancelCollectPayment', { gift, paymentIntent });
    return { type: CANCEL_PAYMENT_COLLECTION };
};

export const releaseSuccess = () => {
    socket.postMessage('releaseSuccess', {});
};
