import CustomConversionTracker from './CustomConversionTracker/CustomConversionTracker';
import { bootstrap } from '../actions/bootstrapActions';
import React, { Component, Fragment } from 'react';
import FixedProgressBar from './FixedProgressBar';
import { connect } from 'react-redux';
import Router from './Router';

class App extends Component {
    componentDidMount() {
        this.props.bootstrap();
    }

    render() {
        return (
            <Fragment>
                <FixedProgressBar />
                <Router />
                <CustomConversionTracker />
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    bootstrap,
};

export default connect(null, mapDispatchToProps)(App);
