import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Component } from 'react';

class Date extends Component {
    render() {
        const { seller, children: date, format = 'L' } = this.props;

        if (seller) {
            return moment.tz(date, seller.timezoneName).format(format);
        }

        return moment(date).format(format);
    }
}

const mapStateToProps = state => ({
    seller: state.seller,
});

export default connect(mapStateToProps)(Date);
