import { BootstrapContext } from '../../actions/bootstrapActions';
import _ from 'lodash';
import { getXWM } from '../../xwm';

class FacebookPixelTracker {
    initialized = false;

    init(seller, bootstrap) {
        if (!this.initialized && bootstrap.context !== BootstrapContext.SELLER) {
            const facebookPixelPreference = _.get(seller, 'preferences.facebookPixelTracking');

            if (
                _.get(facebookPixelPreference, 'enabled') &&
                !_.isEmpty(_.get(facebookPixelPreference, 'facebookPixelId'))
            ) {
                this.initialized = true;
            }
        }
    }

    onGiftPurchase(seller, gift) {
        if (this.initialized) {
            const facebookPixelPreference = _.get(seller, 'preferences.facebookPixelTracking');
            const pixelId = _.get(facebookPixelPreference, 'facebookPixelId');

            const data = _.get(facebookPixelPreference, 'allowAdvancedConversionTracking')
                ? this.getUserData(gift)
                : {};

            const couponCodes = gift.items.map(item => {
                return _.get(item, 'coupon.code', '').toUpperCase();
            });

            const payload = {
                content_type: 'product',
                content_category: 'gift',
                content_name: 'Gift ' + couponCodes.filter(Boolean).join(','),
                value: gift.amount,
                currency: seller.currency,
                num_items: 1,
                city: this.getSellerCity(seller),
            };

            const contentIds = gift.items.map(item => (item.experience ? item.experience.id : null)).filter(Boolean);

            if (contentIds.length > 0) {
                payload.content_ids = contentIds;
            }

            const xwm = getXWM();
            if (xwm) {
                xwm.send('fbPixel.fire', {
                    payload: payload,
                    userData: data,
                    pixelId: pixelId,
                });
            }
        }
    }

    getUserData(gift) {
        const data = {};

        if (gift.customerEmail) {
            data.em = gift.customerEmail.toLowerCase();
        }

        if (gift.customerName) {
            const nameArray = gift.customerName.split(' ');
            data.fn = nameArray[0].toLowerCase();

            if (nameArray.length > 1 && nameArray[nameArray.length - 1]) {
                data.ln = nameArray[nameArray.length - 1].toLowerCase();
            }
        }

        return data;
    }

    getSellerCity(seller) {
        const { city } = seller;

        // Some sellers have the country name included in the "city" field.
        // Requirement of XOL-3904 is to only track the name of the city.
        if (city && city.indexOf(',') >= 0) {
            return city.substring(0, city.indexOf(',')).trim();
        }

        return city;
    }
}

export default new FacebookPixelTracker();
