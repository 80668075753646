import ProductItemBreakdown from '../ProductItem/ProductItemBreakdown';
import { getGiftItemProduct } from '../../selectors/productsSelector';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class GiftItemBreakdown extends Component {
    render() {
        const { product, giftItem, isCustomAmountBreakdown } = this.props;
        return (
            <ProductItemBreakdown
                product={product}
                demographics={giftItem.demographics}
                baseAmount={giftItem.baseAmount}
                quantity={giftItem.quantity}
                isCustomAmountBreakdown={isCustomAmountBreakdown}
            />
        );
    }
}

const mapStateToProps = (state, props) => ({
    product: getGiftItemProduct(state, props.giftItem),
});

export default connect(mapStateToProps)(GiftItemBreakdown);
