import { FETCH_GIFT_OFFERS_REQUESTED, FETCH_GIFT_OFFERS_SUCCEEDED } from '../actions/giftOfferActions';
import { FETCH_EXPERIENCE_REQUESTED, FETCH_EXPERIENCE_SUCCEEDED } from '../actions/experienceActions';
import { SAVE_GIFT_REQUESTED, SAVE_GIFT_SUCCEEDED, SAVE_GIFT_FAILED } from '../actions/giftActions';
import { FETCH_SELLER_REQUESTED, FETCH_SELLER_SUCCEEDED } from '../actions/sellerActions';
import { FETCH_BUTTON_REQUESTED, FETCH_BUTTON_SUCCEEDED } from '../actions/buttonActions';
import { createReducer } from 'redux-starter-kit';

export const initialState = {
    total: 0,
    done: 0,
};

const incrementTotal = state => {
    state.total++;
};

const incrementDone = state => {
    state.done++;
};

const progressReducer = createReducer(initialState, {
    // Handle started requests.
    [FETCH_EXPERIENCE_REQUESTED]: incrementTotal,
    [FETCH_BUTTON_REQUESTED]: incrementTotal,
    [FETCH_GIFT_OFFERS_REQUESTED]: incrementTotal,
    [FETCH_SELLER_REQUESTED]: incrementTotal,
    [SAVE_GIFT_REQUESTED]: incrementTotal,

    // Handle completed requests.
    [FETCH_BUTTON_SUCCEEDED]: incrementDone,
    [FETCH_EXPERIENCE_SUCCEEDED]: incrementDone,
    [FETCH_GIFT_OFFERS_SUCCEEDED]: incrementDone,
    [FETCH_SELLER_SUCCEEDED]: incrementDone,
    [SAVE_GIFT_SUCCEEDED]: incrementDone,

    // Handle failed requests.
    [SAVE_GIFT_FAILED]: incrementDone,
});

export default progressReducer;
