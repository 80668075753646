import React, { Component, Children, cloneElement } from 'react';
import styles from './Stepper.module.scss';
import classNames from 'classnames';

class Stepper extends Component {
    render() {
        const { children, size = 42 } = this.props;

        return (
            <div className={classNames('mx-auto pb-4', styles.main)}>
                <div className="d-flex align-items-center justify-content-between">
                    {Children.map(children, (child, index) =>
                        cloneElement(child, { size, last: Children.count(children) === index + 1 }),
                    )}
                </div>
            </div>
        );
    }
}

export default Stepper;
