import styles from './CouponScheduleSummary.module.scss';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

class CouponScheduleSummary extends Component {
    formatTime(time) {
        return moment(_.padStart(time, 4, '0'), 'HHmm').format('LT');
    }

    formatDate(date) {
        return moment.tz(date, this.props.timeZone).format('l');
    }

    render() {
        const { schedule } = this.props;
        const summary = [];

        const days = schedule.days.length === DAYS.length ? 'Daily' : schedule.days.map(day => DAYS[day]).join(', ');
        summary.push(<span>{days}</span>);

        summary.push(<span>starting</span>);
        summary.push(<var>{this.formatDate(schedule.start || moment())}</var>);

        if (schedule.end) {
            summary.push(<span>to</span>);
            summary.push(<var>{this.formatDate(schedule.end)}</var>);
        } else {
            summary.push(<span>onwards</span>);
        }

        const timeRanges = schedule.timeRanges.map((timeRange, index) => {
            const isLast = schedule.timeRanges.length === index + 1;

            return (
                <var key={index} className={styles.timeRange}>
                    {this.formatTime(timeRange.startTime)} - {this.formatTime(timeRange.endTime)}
                    {isLast ? '' : ', '}
                </var>
            );
        });

        return [...summary, ...timeRanges].map((el, index) => <span key={index}>{el} </span>);
    }
}

export default CouponScheduleSummary;
