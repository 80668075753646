import googleAnalyticsTracker from '../trackers/GoogleAnalytics/googleAnalyticsTracker';
import { addProduct, GiftAmountProduct } from './productActions';
import { fetchGiftOffers } from './giftOfferActions';
import resizeObserver from '../utils/resizeObserver';
import { fetchButton } from './buttonActions';
import { fetchSeller } from './sellerActions';
import { addGiftItem } from './giftActions';
import getParams from '../utils/getParams';
import { navigate } from '@reach/router';
import { setUser } from './userActions';
import { addDevice } from './paymentActions';
import socket from '../socket';
import { initializeXWM } from '../xwm';

export const BOOTSTRAP = 'BOOTSTRAP';
export const UPDATE_BOOTSTRAP_LOCALE = 'UPDATE_BOOTSTRAP_LOCALE';

export const bootstrap = () => async (dispatch, getState) => {
    const params = getParams();
    if (params.accent) {
        global.root.style.setProperty('--primary', params.accent);
    }

    if (params.xdm) {
        socket.initialize(dispatch).postMessage('hideLoader');

        if (params.context === BootstrapContext.SELLER || params.context === BootstrapContext.PHYSICAL_GIFT_CARD) {
            resizeObserver.observe(document.body);
        }
    }
    if (params.xwm) {
        initializeXWM(params.xwm);
    }

    dispatch({ type: BOOTSTRAP, ...params });

    googleAnalyticsTracker.init(getState());

    switch (params.context) {
        case BootstrapContext.SELLER:
            dispatch(setUser({ apiKey: params.apiKey }));
            dispatch(fetchSeller(params.seller));
            if (params.paymentDevice) {
                dispatch(addDevice(params.paymentDevice));
            }
            dispatch(fetchGiftOffers());
            break;
        case BootstrapContext.PHYSICAL_GIFT_CARD:
            dispatch(setUser({ apiKey: params.apiKey }));
            dispatch(addProduct(GiftAmountProduct));
            dispatch(addGiftItem(GiftAmountProduct, { baseAmount: params.value, code: params.gift }));
            dispatch(fetchSeller(params.seller));
            navigate('/payment', { replace: true });
            break;
        case BootstrapContext.CHECKOUT:
            dispatch(fetchSeller(params.seller));
            dispatch(fetchGiftOffers({ system: true, baseGiftProduct: false }));
            break;
        case BootstrapContext.BUTTON:
            dispatch(fetchButton(params.button));
            break;
        default:
    }
};

export const BootstrapContext = {
    SELLER: 'seller',
    BUTTON: 'button',
    CHECKOUT: 'checkout',
    PHYSICAL_GIFT_CARD: 'physicalGiftCard',
};
