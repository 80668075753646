import ModalFooterButton from './Modal/ModalFooterButton';
import classNames from 'classnames';
import { collectPaymentFailed, collectPaymentSucceeded } from '../actions/paymentActions';
import { createPaymentIntent, cancelPaymentCollection } from '../actions/socketActions';
import ModalFooter from './Modal/ModalFooter';
import { ModalHeader } from 'reactstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import chipImage from '../images/chip.png';
import Modal from './Modal/Modal';
import StripeTerminalListener from '../utils/StripeTerminalListener';
import styles from './StripeTerminalModal.module.scss';

class StripeTerminalModal extends Component {
    state = { counter: 5 * 60 };

    componentDidMount() {
        const intent = this.props.payment.intent;

        this.listener = new StripeTerminalListener({
            createPaymentIntent: this.props.createPaymentIntent,
            cancelPaymentCollection: this.props.cancelPaymentCollection,
            success: this.props.collectPaymentSucceeded,
            error: this.props.collectPaymentFailed,
        });
        this.listener.startCollection(intent.clientSecret, this.props.gift, intent);

        this.interval = setInterval(() => {
            if (this.state.counter === 0) {
                this.handleClose();
            }
            this.setState({ counter: this.state.counter - 1 });
        }, 1000);
    }

    handleClose = () => {
        this.listener.stopCollection(this.props.gift, this.props.payment.intent);
        this.props.onClose();
    };

    handleRetry = () => {
        this.props.onRetry();
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    formatTime(time) {
        let minutes = parseInt(time / 60, 10);
        let seconds = parseInt(time % 60, 10);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return minutes + ':' + seconds;
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { isConfirming, isError, errorMessage, device } = this.props.payment;
        const time = this.formatTime(this.state.counter.toString());

        return (
            <Modal size="lg" isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Make Payment</ModalHeader>

                <div className="p-3 d-block text-center">
                    <img className={classNames(styles.info)} alt="chip" src={chipImage} />
                    {isError ? (
                        <div>
                            <div className={classNames(styles.info, 'font-weight-bold')}>
                                The payment failed due to the following error:
                            </div>
                            <div className={classNames(styles.error, 'alert alert-danger center hide')} role="alert">
                                <i className="fas fa-exclamation-circle" /> {errorMessage}
                            </div>
                        </div>
                    ) : isConfirming ? (
                        <div>
                            <div className={classNames(styles.info, 'font-weight-bold')}>
                                Your credit card has been charged
                            </div>
                            <div className="alert alert-success center" role="alert">
                                <i className="far fa-check-circle" /> Payment was successful, finalising your gift
                                purchase
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h1>{time}</h1>
                            <div className={classNames(styles.info, 'font-weight-bold')}>
                                Complete payment using {device.label}
                            </div>
                            <p className={classNames(styles.info)}>
                                You have 5 minutes to complete this transaction, after which the gift will expire.
                            </p>
                            <div className={classNames(styles.notice, 'alert alert-info center')} role="alert">
                                <i className="far fa-clock" /> Waiting for payment
                            </div>
                        </div>
                    )}
                </div>

                <ModalFooter>
                    {isError ? (
                        <ModalFooterButton
                            className="rounded-0 text-uppercase"
                            size="lg"
                            color="secondary"
                            outline
                            onClick={this.handleRetry}
                        >
                            Use another card
                        </ModalFooterButton>
                    ) : null}
                    {isConfirming ? null : (
                        <ModalFooterButton
                            className={classNames(styles.alert, 'rounded-0 text-white text-uppercase')}
                            size="lg"
                            onClick={this.handleClose}
                        >
                            Cancel transaction
                        </ModalFooterButton>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    currency: state.seller.currency,
    countryCode: state.seller.countryCode,
});

const mapDispatchToProps = {
    collectPaymentSucceeded,
    collectPaymentFailed,
    createPaymentIntent,
    cancelPaymentCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeTerminalModal);
