import { getGiftItemProduct, getDuplicateCodes } from '../../selectors/giftSelector';
import { BootstrapContext } from '../../actions/bootstrapActions';
import ExperienceGiftItem from './ExperienceGiftItem';
import CustomAmountGiftItem from './CustomAmountGiftItem';
import GiftOfferGiftItem from './GiftOfferGiftItem';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class GiftItem extends Component {
    getComponent() {
        if (this.props.product?.id) {
            if (this.props.product.experience) {
                return ExperienceGiftItem;
            } else {
                return this.props.product.baseGiftProduct ? CustomAmountGiftItem : GiftOfferGiftItem;
            }
        } else {
            return CustomAmountGiftItem;
        }
    }

    isDuplicateCode() {
        const { duplicateCodes, giftItem } = this.props;
        return duplicateCodes.indexOf(giftItem.code) >= 0;
    }

    render() {
        const Component = this.getComponent();

        if (Component) {
            return <Component {...this.props} isDuplicateCode={this.isDuplicateCode()} />;
        }

        return null;
    }
}

const mapStateToProps = (state, props) => ({
    giftItem: props.giftItem ? props.giftItem : state.gift.items[props.giftItemIndex],
    product: getGiftItemProduct(state, props.giftItemIndex),
    disableCode: state.bootstrap.context !== BootstrapContext.SELLER,
    context: state.bootstrap.context,
    duplicateCodes: getDuplicateCodes(state),
});

export default connect(mapStateToProps)(GiftItem);
