import React, { Component } from 'react';

class Header extends Component {
    render() {
        const { title, children, left, right } = this.props;

        return (
            <div className="p-3">
                <div className="d-flex mb-3">
                    <div className="flex-fill mr-3">{left}</div>
                    <div className="text-right">{right}</div>
                </div>

                <h3 className="mb-3 text-center">{title}</h3>
                {children}
            </div>
        );
    }
}

export default Header;
