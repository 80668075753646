import xdm from 'easyxdm';

class Socket {
    listeners = {};

    initialize(dispatch) {
        if (this.socket) {
            console.warn('Socket is already initialized');
            return this;
        }

        this.socket = new xdm.Socket({
            onMessage: message => {
                try {
                    const { event, data } = JSON.parse(message);

                    // EasyXDM messages have a very similar signature as Redux Actions.
                    // Let's dispatch every message to the store, so our app can use the data.
                    dispatch({ type: event, data });

                    // Notify listeners.
                    if (this.listeners[event]) {
                        this.listeners[event](data);
                    }
                } catch (e) {
                    console.warn('Error parsing message from XDM parent', e);
                }
            },
        });

        return this;
    }

    postMessageRaw(message) {
        if (this.socket) {
            this.socket.postMessage(message);
        }
    }

    postMessage(event, data) {
        this.postMessageRaw(JSON.stringify({ event, data }));
    }

    on(event, listener) {
        this.listeners[event] = listener;
    }

    off(event) {
        delete this.listeners[event];
    }
}

export default new Socket();
