import PurchasedGiftItem from '../components/PurchasedGiftItem';
import Header from '../components/Header';
import React, { Component } from 'react';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import Page from '../components/Page';
import List from '../components/List';

class SuccessPage extends Component {
    componentDidMount() {
        if (this.props.giftItems.length === 1) {
            navigate('/print/0', { replace: true });
        }
    }

    render() {
        const { giftItems } = this.props;

        return (
            <Page>
                <Header title="Thank You for Your Purchase">
                    <div className="text-center">
                        To redeem it, just use the gift code like you would a coupon when booking an experience with us.
                    </div>
                </Header>

                <List className="p-2 p-lg-4">
                    {giftItems.map((giftItem, giftItemIndex) => (
                        <PurchasedGiftItem key={giftItemIndex} giftItemIndex={giftItemIndex} giftItem={giftItem} />
                    ))}
                </List>
            </Page>
        );
    }
}

const mapStateToProps = state => ({
    giftItems: state.gift.items,
});

export default connect(mapStateToProps)(SuccessPage);
