import moment from 'moment';
import _ from 'lodash';

/**
 * Check if the given date is in the coupon restriction schedule.
 *
 * @param {Object} state Application state.
 * @param {String} giftOfferId Gift Offer ID.
 * @param {Moment} date Current date.
 */
export const isGiftOfferWithinPurchaseRestrictions = (state, giftOfferId, date = moment()) => {
    const bookBySchedule = _.get(state, `products.data.${giftOfferId}.couponRestrictions.bookBySchedule`);

    if (bookBySchedule) {
        if (!isInSchedule(bookBySchedule, date)) {
            return false;
        }

        return _.get(bookBySchedule, 'blackouts', []).every(blackout => !isInSchedule(blackout, date));
    }

    return true;
};

/**
 * Check if the given date is in the schedule.
 *
 * @param {CouponSchedule} schedule
 * @param {Moment} date
 */
const isInSchedule = (schedule, date) => {
    const start = schedule.start ? moment(schedule.start) : date;
    const end = schedule.end ? moment(schedule.end) : date;
    const isBetween = date.isSameOrAfter(start, 'day') && date.isSameOrBefore(end, 'day');

    if (!isBetween) {
        return false;
    }

    if (!_.includes(schedule.days, date.day())) {
        return false;
    }

    if (schedule.timeRanges.length > 0) {
        return schedule.timeRanges.some(timeRange => isInTimeRange(timeRange, date));
    }

    return true;
};

/**
 * Check if the given date is in the time range.
 *
 * @param {TimeRange} timeRange
 * @param {Moment} date
 */
const isInTimeRange = (timeRange, date) => {
    const time = Number(date.format('Hmm'));
    return time >= timeRange.startTime && time <= timeRange.endTime;
};
