import socket from '../socket';

/**
 * This class implements methods that are required for the stripe terminal to function
 */
class StripeTerminalListener {
    constructor(options) {
        this.options = options;
    }

    startCollection(clientSecret, gift, paymentIntent) {
        this.disableListeners();

        this.options.createPaymentIntent(clientSecret, gift, paymentIntent);

        socket.on('paymentIntent.success', data => {
            this.options.success(data.gift);
        });

        socket.on('paymentIntent.fail', data => {
            this.options.error(data.gift, data.errorMessage);
        });
    }

    disableListeners() {
        socket.off('paymentIntent.success');
        socket.off('paymentIntent.fail');
    }

    stopCollection(gift, paymentIntent) {
        this.options.cancelPaymentCollection(gift, paymentIntent);
        this.disableListeners();
    }
}

export default StripeTerminalListener;
