import { FormGroup, Label, Col, CustomInput } from 'reactstrap';
import React, { Component } from 'react';
import FormField from './FormField';
import domId from '../utils/domId';
import { Field } from 'formik';

class CustomerForm extends Component {
    id = {
        name: domId(this, 'name'),
        email: domId(this, 'email'),
        phone: domId(this, 'message'),
        sendToCustomer: domId(this, 'sendToCustomer'),
    };

    render() {
        const { className, showCheckbox } = this.props;
        return (
            <div className={className}>
                <FormGroup row>
                    <Label for={this.id.name} sm={2}>
                        Name
                    </Label>

                    <Col sm={10}>
                        <FormField name="customerName" id={this.id.name} placeholder="Your full name" />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for={this.id.email} sm={2}>
                        Email
                    </Label>

                    <Col sm={10}>
                        <FormField className="mb-2" name="customerEmail" id={this.id.email} placeholder="Your email" />

                        {showCheckbox ? (
                            <Field
                                as={CustomInput}
                                type="checkbox"
                                name="sendToCustomer"
                                id={this.id.sendToCustomer}
                                label="Email gift certificate to me"
                            />
                        ) : (
                            'Gift certificate will be emailed to you'
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for={this.id.message} sm={2}>
                        Phone
                    </Label>

                    <Col sm={10}>
                        <FormField name="phone" id={this.id.phone} placeholder="Your phone number" />
                    </Col>
                </FormGroup>
            </div>
        );
    }
}

export default CustomerForm;
