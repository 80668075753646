import { ProductObject } from '../../actions/productActions';
import ExperienceProductItem from './ExperienceProductItem';
import CustomAmountProductItem from './CustomAmountProductItem';
import GiftOfferProductItem from './GiftOfferProductItem';
import React, { Component } from 'react';

class ProductItem extends Component {
    getComponent() {
        if (this.props.product) {
            if (this.props.product.baseGiftProduct) {
                return CustomAmountProductItem;
            } else if (this.props.product.system && this.props.product.experience) {
                return ExperienceProductItem;
            } else if (this.props.product.object === ProductObject.GIFT_OFFER) {
                return GiftOfferProductItem;
            }
        }
    }

    render() {
        const Component = this.getComponent();

        if (Component) {
            return <Component {...this.props} />;
        }

        return null;
    }
}

export default ProductItem;
