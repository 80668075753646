import {
    FETCH_GIFT_OFFERS_REQUESTED,
    FETCH_GIFT_OFFERS_SUCCEEDED,
    FETCH_GIFT_OFFERS_FAILED,
} from '../actions/giftOfferActions';

import { FETCH_EXPERIENCE_SUCCEEDED, FETCH_EXPERIENCE_FAILED } from '../actions/experienceActions';

import { FETCH_BUTTON_SUCCEEDED, FETCH_BUTTON_REQUESTED } from '../actions/buttonActions';
import { GiftAmountProduct, ADD_PRODUCT } from '../actions/productActions';
import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

export const initialState = {
    loading: false,
    data: {},
    experiences: {},
    baseGiftProduct: {},
};

const productsReducer = createReducer(initialState, {
    [FETCH_BUTTON_REQUESTED](state) {
        state.loading = true;
    },

    [FETCH_BUTTON_SUCCEEDED](state, { button }) {
        state.loading = false;
        const products = button.items.map(item => item.product || GiftAmountProduct);
        _.assign(
            state.data,
            _.mapKeys(products, product => product.id),
        );

        button.items.forEach(buttonItem => {
            if (buttonItem.product.experience?.id && !state.experiences[buttonItem.product.experience.id]) {
                state.experiences[buttonItem.product.experience.id] = buttonItem.product.experience;
            }
        });
    },

    [FETCH_EXPERIENCE_SUCCEEDED](state, { experience }) {
        state.experiences[experience.id] = experience;
    },

    [FETCH_EXPERIENCE_FAILED](state) {
        state.loading = false;
    },

    [FETCH_GIFT_OFFERS_REQUESTED](state) {
        state.loading = true;
    },

    [FETCH_GIFT_OFFERS_SUCCEEDED](state, { giftOffers }) {
        state.loading = false;
        _.assign(
            state.data,
            _.mapKeys(giftOffers, giftOffer => giftOffer.id),
        );

        giftOffers.forEach(giftOffer => {
            if (giftOffer.experience?.id && !state.experiences[giftOffer.experience.id]) {
                state.experiences[giftOffer.experience.id] = giftOffer.experience;
            }
        });

        state.baseGiftProduct = giftOffers.some(giftOffer => giftOffer.baseGiftProduct);
    },

    [FETCH_GIFT_OFFERS_FAILED](state) {
        state.loading = false;
    },

    [ADD_PRODUCT](state, { product }) {
        state.data[product.id] = product;
    },
});

export default productsReducer;
