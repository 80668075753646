import getUserLocale from 'get-user-locale';
import facebookPixelTracker from '../trackers/FacebookPixel/facebookPixelTracker';
import { fetchGiftOfferExperiences } from './experienceActions';
import { showError } from './errorActions';
import xolaApi from '../api/xolaApi';
import localize, { getLanguageCode } from '../localize';
import { isLanguageCodeSupported } from '../selectors/sellerSelectors';
import { UPDATE_BOOTSTRAP_LOCALE } from './bootstrapActions';

export const FETCH_BUTTON_REQUESTED = 'FETCH_BUTTON_REQUESTED';
export const FETCH_BUTTON_SUCCEEDED = 'FETCH_BUTTON_SUCCEEDED';
export const FETCH_BUTTON_FAILED = 'FETCH_BUTTON_FAILED';

/**
 * Fetch button by ID.
 * Since gift offer items from the button depends on some experiences,
 * they will also be fetched for each gift offer in the button.
 * @param {string} id Button ID.
 */
export const fetchButton = id => async (dispatch, getState) => {
    try {
        dispatch({ type: FETCH_BUTTON_REQUESTED, id });
        const { data: button } = await xolaApi.get(`/api/buttons/${id}`, {
            params: { expand: 'seller,product,experience', buttonLocale: getState().bootstrap.locale },
        });
        dispatch({ type: FETCH_BUTTON_SUCCEEDED, button });

        const userLanguageCode = getLanguageCode(getUserLocale());
        let selectedLanguageCode = null;
        let buttonLanguageCode = null;

        if (getState().bootstrap.locale) {
            buttonLanguageCode = getLanguageCode(getState().bootstrap.locale);
            selectedLanguageCode = buttonLanguageCode;
        }

        if (buttonLanguageCode && !isLanguageCodeSupported(getState(), buttonLanguageCode)) {
            if (isLanguageCodeSupported(getState(), userLanguageCode)) {
                dispatch({ type: UPDATE_BOOTSTRAP_LOCALE, locale: userLanguageCode });
                dispatch(fetchButton(id));
                return;
            } else {
                selectedLanguageCode = getLanguageCode(button.seller.locale);
            }
        }

        // Initialize localization.
        localize(button.seller, selectedLanguageCode);

        // Initialize Facebook Pixel.
        facebookPixelTracker.init(button.seller, getState().bootstrap);

        // Fetch experiences for coupon restrictions of gift offers.
        button.items.forEach(item => dispatch(fetchGiftOfferExperiences(item.product)));
    } catch (error) {
        dispatch({ type: FETCH_BUTTON_FAILED, id });
        dispatch(showError('Failed to Load the Button', error));
    }
};

export const ButtonItemObject = {
    OBJECT_GIFT_AMOUNT: 'gift_amount_button_item',
    OBJECT_GIFT_OFFER: 'gift_offer_button_item',
    OBJECT_EXPERIENCE: 'experience_button_item',
};
