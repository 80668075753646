import { setCaptchaResponse } from '../actions/captchaActions';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { Component } from 'react';
import { ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Modal from './Modal/Modal';
import config from '../config';

class CaptchaModal extends Component {
    handleSuccess = response => {
        const { setCaptchaResponse, onSuccess } = this.props;
        setCaptchaResponse(response);
        onSuccess();
    };

    render() {
        const { isOpen, toggle } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalBody className="text-center">
                    <ReCAPTCHA
                        className="d-inline-block mt-2"
                        sitekey={config.recaptcha.siteKey}
                        onChange={this.handleSuccess}
                    />
                </ModalBody>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    setCaptchaResponse,
};

export default connect(null, mapDispatchToProps)(CaptchaModal);
