import { dismissLatestError } from '../actions/errorActions';
import styles from './Errors.module.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

class Errors extends Component {
    state = { showDetails: false };

    handleCloseClick = () => {
        this.setState({ showDetails: false });
        this.props.dismissLatestError();
    };

    handleShowDetailsClick = () => {
        this.setState({ showDetails: !this.state.showDetails });
    };

    render() {
        const { latestError } = this.props;
        const { showDetails } = this.state;

        if (!latestError) {
            return null;
        }

        return (
            <div className="fixed-top p-3">
                <div
                    className={classNames('alert alert-danger alert-dismissible shadow-lg mx-auto', styles.alert)}
                    role="alert"
                >
                    <strong>{latestError.title}</strong>
                    {latestError.message ? <div className="py-2">{latestError.message}</div> : null}
                    {latestError.details ? (
                        <div
                            className={classNames('btn btn-link text-dark p-0', styles.button)}
                            role="button"
                            onClick={this.handleShowDetailsClick}
                        >
                            <small>{showDetails ? 'Hide' : 'Show'} Details</small>
                        </div>
                    ) : null}
                    {showDetails ? (
                        <div className="pt-2">
                            <small>
                                <pre className="m-0">{JSON.stringify(latestError.details, null, 2).trim()}</pre>
                            </small>
                        </div>
                    ) : null}
                    <button type="button" className="close" onClick={this.handleCloseClick}>
                        <i className="fas fa-times" />
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    latestError: state.errors[state.errors.length - 1],
});

const mapDispatchToProps = {
    dismissLatestError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
