import { isSellerContext } from '../../selectors/bootstrapSelectors';
import { getPaymentTokenization } from '../../selectors/sellerSelectors';
import CreditCardExpiryField from '../CreditCardExpiryField';
import { showError } from '../../actions/errorActions';
import { updateGift } from '../../actions/giftActions';
import CreditCardFormLayout from './CreditCardLayout';
import StripeElementsForm from './StripeElementsForm';
import React, { Component, Fragment } from 'react';
import CreditCardSwiper from './CreditCardSwiper';
import FormFieldNumber from '../FormFieldNumber';
import { connect } from 'react-redux';
import FormField from '../FormField';
import { Field } from 'formik';
import _ from 'lodash';

class CreditCardForm extends Component {
    handleError = message => {
        this.props.showError(message);
    };

    showStripeElements(form) {
        // Hide Stripe Elements and show regular CC form if card swiper is enabled and a card is swiped.
        return this.props.useStripeElements && !_.get(form.values, 'payment.card.number');
    }

    isCardEncrypted(form) {
        return _.has(form.values, 'payment.card.encrypted');
    }

    render() {
        const { enableSwipe, updateGift, paymentTokenization } = this.props;

        return (
            <Field>
                {({ form }) => (
                    <Fragment>
                        <CreditCardSwiper
                            enable={enableSwipe}
                            onError={this.handleError}
                            isTokenizationEnabled={paymentTokenization.stripeElementsEnabled}
                            onSwipe={card => {
                                form.setFieldValue('payment.card', card, true);
                                updateGift({ payment: { card } });
                            }}
                        />

                        {this.showStripeElements(form) ? (
                            <StripeElementsForm />
                        ) : (
                            <CreditCardFormLayout
                                name={
                                    <FormField
                                        name="payment.card.billingName"
                                        aria-label="Name on card"
                                        placeholder="Name on card"
                                        disabled={this.isCardEncrypted(form)}
                                    />
                                }
                                number={
                                    <FormFieldNumber
                                        name="payment.card.number"
                                        aria-label="Card Number"
                                        placeholder="1234 1234 1234 1234"
                                        disabled={this.isCardEncrypted(form)}
                                        format={
                                            this.isCardEncrypted(form) ? '#### **** **** ####' : '#### #### #### ####'
                                        }
                                    />
                                }
                                cvv={
                                    <FormField
                                        aria-label="CVC"
                                        name="payment.card.cvv"
                                        maxLength={4}
                                        placeholder="CVC"
                                    />
                                }
                                expiry={<CreditCardExpiryField disabled={this.isCardEncrypted(form)} />}
                                zip={
                                    <FormField
                                        aria-label="Zip Code"
                                        name="payment.card.billingPostcode"
                                        placeholder="Zip Code"
                                    />
                                }
                            />
                        )}
                    </Fragment>
                )}
            </Field>
        );
    }
}

const mapStateToProps = state => ({
    enableSwipe: isSellerContext(state),
    paymentTokenization: getPaymentTokenization(state.seller),
});

const mapDispatchToProps = {
    showError,
    updateGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
