import { SET_CAPTCHA_RESPONSE } from '../actions/captchaActions';
import { createReducer } from 'redux-starter-kit';

export const initialState = {
    response: null,
};

const captchaReducer = createReducer(initialState, {
    [SET_CAPTCHA_RESPONSE](state, { response }) {
        state.response = response;
    },
});

export default captchaReducer;
