import { SAVE_GIFT_REQUESTED, PaymentMethod, GiftSource } from '../actions/giftActions';

import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

export const initialState = {
    items: [],
    payment: { method: PaymentMethod.CREDIT_CARD, comment: '' },
    source: GiftSource.GIFT,
};

const requestReducer = createReducer(initialState, {
    [SAVE_GIFT_REQUESTED](state, { gift }) {
        _.merge(state, gift);
    },
});

export default requestReducer;
