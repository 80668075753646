import NumberFormat from 'react-number-format';
import { FormFeedback } from 'reactstrap';
import React, { Fragment } from 'react';
import { Field, getIn } from 'formik';
import classNames from 'classnames';

const FormFieldNumber = ({ name, className, ...rest }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const isTouched = getIn(form.touched, field.name);
                const error = (isTouched || form.submitCount > 0) && getIn(form.errors, field.name);

                return (
                    <Fragment>
                        <NumberFormat
                            {...field}
                            {...rest}
                            className={classNames(className, 'form-control', { 'is-invalid': !!error })}
                        />

                        <FormFeedback>{error}</FormFeedback>
                    </Fragment>
                );
            }}
        </Field>
    );
};

export default FormFieldNumber;
