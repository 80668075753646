import CouponRestrictions from '../components/CouponRestrictions/CouponRestrictions';
import { getGiftItemProduct } from '../selectors/productsSelector';
import ProductImage from '../components/ProductImage';
import Currency from '../components/Currency';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from '../components/Page';
import { Button } from 'reactstrap';

class PrintPage extends Component {
    handlePrintClick = () => {
        window.print();
    };

    getEmails() {
        const { giftItem, gift } = this.props;
        const emails = [];

        if (giftItem.sendToCustomer) {
            emails.push(gift.customerEmail);
        }

        if (giftItem.sendToRecipient) {
            emails.push(giftItem.recipientEmail);
        }

        return emails.join(' and ');
    }

    handleBackClick = () => {
        window.history.back();
    };

    render() {
        const { gift, giftItem, product } = this.props;

        return (
            <Page>
                <div className="p-5 text-center">
                    <ProductImage className="d-inline-block mb-4" product={product} style={{ width: 120 }} />
                    <h3>
                        A Gift from <var>{gift.customerName}</var>
                    </h3>

                    <p className="text-primary">
                        <strong notranslate="">
                            <Currency>{giftItem.baseAmount}</Currency>
                        </strong>
                    </p>

                    <p>
                        Gift Code:{' '}
                        <strong className="text-uppercase" notranslate="">
                            {giftItem.coupon.code}
                        </strong>
                    </p>

                    <CouponRestrictions className="mb-4" couponRestrictions={product.couponRestrictions} />

                    <Button className="d-print-none mb-5" onClick={this.handlePrintClick} outline>
                        Print
                    </Button>

                    <div className="border-bottom text-center mb-4" style={{ height: 14 }}>
                        <div className="bg-white px-4 d-inline-block text-uppercase">Instructions</div>
                    </div>

                    <p className="text-muted">
                        Thanks for your purchase! A copy of the gift certificate has been mailed to{' '}
                        <var>{this.getEmails()}</var>.
                        <br />
                        To redeem it, just use the gift code like you would a coupon when booking an experience with us.
                    </p>

                    {gift.items.length > 1 ? (
                        <div className="text-left d-print-none">
                            <Button aria-label="Back" onClick={this.handleBackClick} outline>
                                <i className="fas fa-chevron-left" />
                            </Button>
                        </div>
                    ) : null}
                </div>
            </Page>
        );
    }
}

const mapStateToProps = (state, props) => ({
    gift: state.gift,
    giftItem: state.gift.items[props.giftItemIndex],
    product: getGiftItemProduct(state, state.gift.items[props.giftItemIndex]),
});

export default connect(mapStateToProps)(PrintPage);
