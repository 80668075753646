import _ from 'lodash';

/**
 * Create a unique ID for dom elements.
 * Useful for connecting form inputs to labels and such.
 * @param {React.Component} component React component instance.
 * @param {string} name Optional suffix for the ID.
 */
const domId = (component, name) => {
    return _.uniqueId(component.constructor.name + (name ? `-${name}` : ''));
};

export default domId;
