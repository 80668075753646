import styles from './Breakdown.module.scss';
import React, { Component } from 'react';
import classNames from 'classnames';
import Currency from './Currency';
import _ from 'lodash';
import HoverTooltip from './HoverTooltip';

class Breakdown extends Component {
    getTotal() {
        const { children: items } = this.props;
        return _.sumBy(items, 'value');
    }

    render() {
        const { children } = this.props;
        const total = this.getTotal();

        return (
            <table className="table table-borderless table-sm mb-0">
                <tbody>
                    {children
                        .filter(item => item.value !== 0)
                        .map((item, index) => (
                            <tr key={index}>
                                <td className={styles.cell}>
                                    {item.tooltip ? (
                                        <HoverTooltip placement="bottom" content={item.tooltip}>
                                            <span
                                                aria-label={item.title + ' ' + item.tooltip}
                                                className={styles.prepaidFeesText}
                                            >
                                                {item.title}
                                            </span>
                                        </HoverTooltip>
                                    ) : (
                                        item.title
                                    )}
                                </td>

                                <td className={classNames(styles.cell, 'text-right')} notranslate="">
                                    {item.value ? <Currency>{item.value}</Currency> : null}
                                </td>
                            </tr>
                        ))}
                </tbody>

                {total !== 0 ? (
                    <tfoot className="text-accent">
                        <tr>
                            <th className={styles.cell}>TOTAL</th>

                            <th className={classNames(styles.cell, 'text-right')} notranslate="">
                                <Currency>{this.getTotal()}</Currency>
                            </th>
                        </tr>
                    </tfoot>
                ) : null}
            </table>
        );
    }
}

export default Breakdown;
