import { showError } from '../actions/errorActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Errors from './Errors';

class ErrorLogger extends Component {
    state = { hasError: false };

    componentDidCatch(error) {
        this.props.showError('Something went wrong', error);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <Errors />;
        }

        return this.props.children;
    }
}

const mapDispatchToProps = {
    showError,
};

export default connect(null, mapDispatchToProps)(ErrorLogger);
