import { showError } from './errorActions';
import xolaApi from '../api/xolaApi';

export const FETCH_EXPERIENCE_REQUESTED = 'FETCH_EXPERIENCE_REQUESTED';
export const FETCH_EXPERIENCE_SUCCEEDED = 'FETCH_EXPERIENCE_SUCCEEDED';
export const FETCH_EXPERIENCE_FAILED = 'FETCH_EXPERIENCE_FAILED';

/**
 * Fetch experience by ID.
 * @param {string} id Experience ID.
 */
export const fetchExperience = id => async dispatch => {
    try {
        dispatch({ type: FETCH_EXPERIENCE_REQUESTED, id });
        const { data: experience } = await xolaApi.get(`/api/experiences/${id}`);
        dispatch({ type: FETCH_EXPERIENCE_SUCCEEDED, experience });
    } catch (error) {
        dispatch({ type: FETCH_EXPERIENCE_FAILED, id });
        dispatch(showError('Failed to Load the Experience', error));
    }
};

/**
 * Calls `fetchExperience` action creator for all experiences in the gift offer.
 * It will skip experiences already in the state.
 * @param {Object} giftOffer Gift offer product.
 */
export const fetchGiftOfferExperiences = giftOffer => async (dispatch, getState) => {
    if (giftOffer.couponRestrictions?.experiences?.experiences?.length > 0) {
        giftOffer.couponRestrictions.experiences.experiences.forEach(id => {
            if (!getState().products.experiences[id]) {
                dispatch(fetchExperience(id));
            }
        });
    }
};
