import { isSellerContext } from '../selectors/bootstrapSelectors';
import { closeWindow } from '../actions/socketActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class CloseButton extends Component {
    handleClick = e => {
        e.preventDefault();
        this.props.closeWindow();
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="px-1 text-right d-print-none fixed-top">
                <a onClick={this.handleClick} href="/">
                    <i className="fas fa-times text-secondary" />
                </a>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    show: state.bootstrap.xdm && !isSellerContext(state),
});

const mapDispatchToProps = {
    closeWindow,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseButton);
