import React, { Component } from 'react';

class CouponRestriction extends Component {
    render() {
        const { title, children } = this.props;

        return (
            <div>
                <small className="text-muted">{title}: </small>
                <small>{children}</small>
            </div>
        );
    }
}

export default CouponRestriction;
