import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PaymentMethodButton from './PaymentMethodButton';
import styles from './PaymentMethodButton.module.scss';
import React, { Component } from 'react';
import domId from '../../utils/domId';
import classNames from 'classnames';

class OtherPaymentMethodButton extends Component {
    id = domId(this);

    getSelectedPaymentMethod() {
        const { paymentMethods, method } = this.props;
        return paymentMethods.find(paymentMethod => paymentMethod.id === method);
    }

    render() {
        const { className, method, checked, paymentMethods, onChange } = this.props;

        if (paymentMethods.length === 1) {
            const [paymentMethod] = paymentMethods;

            return (
                <PaymentMethodButton
                    title={paymentMethod.name}
                    icon={<i className="text-dark fas fa-ellipsis-h" />}
                    onClick={() => onChange(paymentMethod.id)}
                    checked={method === paymentMethod.id}
                    className={className}
                />
            );
        }

        const selectedPaymentMethod = this.getSelectedPaymentMethod();

        return (
            <div className={classNames(className, 'd-inline-block text-center mr-2')}>
                <UncontrolledButtonDropdown>
                    <DropdownToggle
                        title={selectedPaymentMethod ? selectedPaymentMethod.name : 'Other'}
                        className={classNames(styles.main, 'rounded-lg py-1 mb-1', {
                            [styles.mainSelected]: checked,
                            'bg-light border-light': !checked,
                            'border-primary': checked,
                        })}
                        color="primary"
                        outline
                    >
                        <i className="text-dark fas fa-ellipsis-h" />

                        <small className="d-block text-dark text-uppercase text-truncate">
                            {selectedPaymentMethod ? selectedPaymentMethod.name : 'Other'}
                        </small>
                    </DropdownToggle>

                    <DropdownMenu>
                        {paymentMethods.map(paymentMethod => (
                            <DropdownItem key={paymentMethod.id} onClick={() => onChange(paymentMethod.id)}>
                                {paymentMethod.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>

                <div>
                    {selectedPaymentMethod ? (
                        <i className="fas fa-dot-circle text-primary" />
                    ) : (
                        <i className="far fa-circle text-secondary" />
                    )}
                </div>
            </div>
        );
    }
}

export default OtherPaymentMethodButton;
