import CreditCardExpiryInput from './CreditCardExpiryInput';
import getFormError from '../utils/getFieldError';
import React, { Component } from 'react';
import { Field, getIn } from 'formik';

class CreditCardExpiryField extends Component {
    render() {
        const monthField = 'payment.card.expiryMonth';
        const yearField = 'payment.card.expiryYear';

        return (
            <Field>
                {({ form }) => {
                    const month = getIn(form.values, monthField);
                    const year = getIn(form.values, yearField);

                    return (
                        <CreditCardExpiryInput
                            value={{ year, month }}
                            error={getFormError(form, monthField) || getFormError(form, yearField)}
                            onBlur={() => {
                                form.setFieldTouched(monthField);
                                form.setFieldTouched(yearField);
                            }}
                            onChange={({ month, year }) => {
                                form.setFieldValue(monthField, month);
                                form.setFieldValue(yearField, year);
                            }}
                            {...this.props}
                        />
                    );
                }}
            </Field>
        );
    }
}

export default CreditCardExpiryField;
