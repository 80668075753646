import React, { Component } from 'react';
import classNames from 'classnames';

class Legend extends Component {
    render() {
        const { className, children, right } = this.props;

        return (
            <div className={classNames(className, 'd-flex border-bottom pb-2 text-uppercase')}>
                <strong className="mr-auto">{children}</strong>
                <strong>{right}</strong>
            </div>
        );
    }
}

export default Legend;
