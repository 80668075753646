import _ from 'lodash';

class GoogleAnalyticsTracker {
    initialized = false;

    /**
     * Initialize seller's tracker for Google Analytics.
     */
    init(state) {
        const { bootstrap } = state;

        if (_.isFunction(window.ga) && bootstrap.gaCode) {
            window.ga('create', bootstrap.gaCode, 'auto', {
                name: 'merchant',
                clientId: bootstrap.gaClient,
                // Set samesite and secure flags so that cross-domain cookies work
                cookieFlags: 'max-age=7200;secure;samesite=none',
            });
            window.ga('merchant.require', 'ecommerce');
            this.pageUrl = bootstrap.gaPage;
            this.initialized = true;
        }
    }

    /**
     * Send gift order to analytics.
     *
     * @param {GoogleAnalyticsGift} googleAnalyticsGift
     */
    trackGift(googleAnalyticsGift) {
        if (this.initialized) {
            if (this.pageUrl) {
                window.ga('merchant.set', 'page', this.pageUrl);
            }

            const object = googleAnalyticsGift.toGoogleAnalyticsObject();
            window.ga('merchant.ecommerce:addTransaction', object.transaction);

            _.forEach(object.products, product => {
                window.ga('merchant.ecommerce:addItem', product);
            });

            window.ga('merchant.ecommerce:send');
        }
    }
}

export default new GoogleAnalyticsTracker();
