import React, { Component } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';

class ModalFooterButton extends Component {
    render() {
        const { className, ...rest } = this.props;
        return <Button className={classNames('m-0 rounded-0 border-0 flex-fill', className)} {...rest} />;
    }
}

export default ModalFooterButton;
