import React, { Component, Fragment } from 'react';
import { Input, FormFeedback } from 'reactstrap';

class GiftCodeInput extends Component {
    handleCodeChange = e => {
        this.props.onChange(e.target.value ? e.target.value.toUpperCase() : null);
    };

    render() {
        const { error, value, ...rest } = this.props;

        return (
            <Fragment>
                <Input
                    className={error ? 'is-invalid' : ''}
                    value={value ? value.toUpperCase() : ''}
                    placeholder="Enter optional gift code"
                    {...rest}
                    onChange={this.handleCodeChange}
                />

                <FormFeedback>{error}</FormFeedback>
            </Fragment>
        );
    }
}

export default GiftCodeInput;
