import { createReducer } from 'redux-starter-kit';
import { SET_USER } from '../actions/userActions';
import _ from 'lodash';

export const initialState = null;

const userReducer = createReducer(initialState, {
    [SET_USER](state, { user }) {
        return _.assign(state, user);
    },
});

export default userReducer;
