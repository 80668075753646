import { FETCH_BUTTON_SUCCEEDED } from '../actions/buttonActions';
import { FETCH_SELLER_SUCCEEDED } from '../actions/sellerActions';
import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

export const initialState = {};

const experiencesReducer = createReducer(initialState, {
    [FETCH_SELLER_SUCCEEDED](state, { seller }) {
        return _.omit(seller, 'fees');
    },

    [FETCH_BUTTON_SUCCEEDED](state, { button }) {
        return _.omit(button.seller, 'fees');
    },
});

export default experiencesReducer;
