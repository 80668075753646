import {
    PaymentMethod,
    GiftItemStatus,
    removeGiftItem,
    saveGift,
    updateGift,
    updateGiftItem,
    releaseGift,
    changePaymentMethod,
    commitGift,
} from '../actions/giftActions';
import {
    getPaymentTokenization,
    isCaptchaEnabled,
    allowMultiItemGifts,
    isThreeDSecureEnabled,
    canUseEMVPayment,
} from '../selectors/sellerSelectors';
import { areGiftItemsValid, getGiftAmount, getPayableAmount, isCustomerEmailRequired } from '../selectors/giftSelector';
import CashCalculatorModal from '../components/CashCalculatorModal';
import PaymentPageButtons from '../components/PaymentPageButtons';
import { BootstrapContext } from '../actions/bootstrapActions';
import PaymentInput from '../components/PaymentInput';
import Stepper, { Step } from '../components/Stepper';
import CaptchaModal from '../components/CaptchaModal';
import CustomerForm from '../components/CustomerForm';
import LoadingModal from '../components/LoadingModal';
import paymentSchema from '../schemas/paymentSchema';
import { injectStripe } from 'react-stripe-elements';
import { showError } from '../actions/errorActions';
import SellerLogo from '../components/SellerLogo';
import styles from './PaymentPage.module.scss';
import Currency from '../components/Currency';
import GiftItem from '../components/GiftItem';
import magensaApi from '../api/magensaApi';
import Errors from '../components/Errors';
import Header from '../components/Header';
import Legend from '../components/Legend';
import React, { Component } from 'react';
import { navigate } from '@reach/router';
import Page from '../components/Page';
import List from '../components/List';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import _ from 'lodash';
import StripeTerminalModal from '../components/StripeTerminalModal';
import { cancelPaymentCollection } from '../actions/socketActions';
import { setGiftError } from '../actions/giftActions';

class PaymentPage extends Component {
    state = {
        openCashCalculator: false,
        openCaptcha: false,
        isCollecting: false,
        loading: false,
        stripeError: null,
    };

    componentDidMount() {
        if (this.props.gift.items.length === 0) {
            navigate('/', { replace: true });
        }
    }

    handleGiftSubmit = async gift => {
        const {
            paymentRequired,
            updateGift,
            showError,
            paymentTokenization,
            stripe,
            currency,
            isCaptchaEnabled,
            isThreeDSecureEnabled,
        } = this.props;

        // Save current gift state in the store.
        updateGift(gift);
        this.setState({ loading: true });

        if (paymentRequired && isThreeDSecureEnabled) {
            this.props.changePaymentMethod(PaymentMethod.INTENT);
        } else if (
            paymentRequired &&
            gift.payment.card &&
            gift.payment.card.encrypted &&
            paymentTokenization.stripeElementsEnabled
        ) {
            const { card } = gift.payment;

            const { data: token } = await magensaApi
                .post('/stripe/tokens', {
                    encryptedCardSwipe: card.encrypted,
                    cvc: card.cvv,
                    zip: card.billingPostcode,
                })
                .catch(() => {
                    showError('Failed to tokenize the card');
                });

            if (token) {
                updateGift({ payment: { card: { token: token.id } } });
            } else {
                return this.setState({ loading: false });
            }
        } else if (
            paymentRequired &&
            gift.payment.method === PaymentMethod.CREDIT_CARD &&
            paymentTokenization.stripeElementsEnabled &&
            !_.get(gift, 'payment.card.number')
        ) {
            try {
                const { token, error } = await stripe.createToken({
                    currency: currency,
                });
                if (token) {
                    updateGift({ payment: { card: { token: token.id } } });
                } else {
                    if (error && error.message) {
                        this.props.setGiftError(error.message);
                    }
                    return this.setState({ loading: false });
                }
            } catch (e) {
                console.log(e);
            }
        }

        if (gift.payment.method === PaymentMethod.CASH) {
            return this.setState({ openCashCalculator: true });
        }

        if (isCaptchaEnabled) {
            return this.setState({ openCaptcha: true });
        }

        await this.saveGift();
    };

    async saveGift() {
        const { gift, saveGift } = this.props;

        if (await saveGift(gift)) {
            const { isThreeDSecureEnabled, giftAmount } = this.props;
            const isOnHold = gift.items.find(item => item.status === GiftItemStatus.STATUS_HOLD);

            if (isOnHold && isThreeDSecureEnabled && giftAmount === 0) {
                await this.props.commitGift();
            } else if (gift.payment.method === PaymentMethod.INTENT) {
                await this.processIntentPayment();
            } else {
                navigate('/success');
            }
        }

        this.setState({ loading: false });
    }

    async processIntentPayment() {
        const { gift, isThreeDSecureEnabled, stripe, elements } = this.props;

        if (isThreeDSecureEnabled && gift.paymentIntents) {
            const response = await stripe.confirmCardPayment(gift.paymentIntents[0].clientSecret, {
                payment_method: { card: elements.getElement('card') },
            });
            if (response.error) {
                let errorMessage = response.error.message;
                errorMessage = errorMessage || 'Error processing transaction';
                await this.props.releaseGift();
                this.props.showError(errorMessage);
            } else {
                await this.props.commitGift();
            }
        } else {
            this.setState({ isCollecting: true });
        }
    }

    handleCaptchaSuccess = () => {
        this.setState({ openCaptcha: false });
        this.saveGift();
    };

    handleCashCalculatorSubmit = async () => {
        this.setState({ openCashCalculator: false });
        this.saveGift();
    };

    handleCloseCashCalculator = () => {
        this.setState({ openCashCalculator: false, loading: false });
    };

    handleCloseTerminal = () => {
        this.props.releaseGift();
        this.setState({ isCollecting: false });
    };

    handleRetryTerminal = async () => {
        this.setState({ isCollecting: false });
        await this.props.cancelPaymentCollection(this.props.gift, this.props.payment.intent);
        this.setState({ isCollecting: true });
    };

    handleAddAnotherGiftClick = gift => () => {
        this.props.updateGift(gift);
    };

    handleBackClick = gift => () => {
        this.props.updateGift(gift);
        const lastItemIndex = this.props.gift.items.length - 1;
        navigate(`/personalize/${lastItemIndex}/edit`);
    };

    handleGiftItemChange = (giftItemIndex, giftItem) => {
        this.props.updateGiftItem(giftItemIndex, giftItem);
    };

    getInitialValues() {
        const { gift, context } = this.props;

        return {
            customerName: gift.customerName,
            customerEmail: gift.customerEmail,
            phone: gift.phone,
            payment: gift.payment,
            sendToCustomer:
                context === BootstrapContext.CHECKOUT ||
                context === BootstrapContext.BUTTON ||
                context === BootstrapContext.SELLER,
        };
    }

    getValidationSchema() {
        const {
            paymentRequired,
            phoneRequired,
            paymentTokenization,
            gift,
            customPaymentMethods,
            customerEmailRequired,
        } = this.props;

        return paymentSchema({
            phoneRequired,
            paymentRequired,
            customerEmailRequired,
            stripeElementsEnabled: paymentTokenization.stripeElementsEnabled && !_.get(gift, 'payment.card.number'),
            customPaymentMethods: customPaymentMethods,
        });
    }

    render() {
        const {
            gift,
            giftAmount,
            payableAmount,
            request,
            context,
            areGiftItemsValid,
            allowMultiItemGifts,
            paymentRequired,
            customerEmailRequired,
        } = this.props;
        const { openCashCalculator, openCaptcha, isCollecting, loading } = this.state;
        const showButtons = context !== BootstrapContext.PHYSICAL_GIFT_CARD;
        const model = gift.paymentIntents ? request : gift;
        return (
            <Page>
                <Errors />

                <Header title="Review and Pay" left={<SellerLogo />}>
                    <Stepper>
                        <Step done>1</Step>
                        <Step done>2</Step>
                        <Step current>3</Step>
                    </Stepper>
                </Header>

                <CashCalculatorModal
                    isOpen={openCashCalculator}
                    toggle={this.handleCloseCashCalculator}
                    expectedAmount={giftAmount}
                    onSubmit={this.handleCashCalculatorSubmit}
                />
                {isCollecting ? (
                    <StripeTerminalModal
                        isOpen={isCollecting}
                        payment={this.props.payment}
                        onClose={this.handleCloseTerminal}
                        onRetry={this.handleRetryTerminal}
                    />
                ) : null}

                <LoadingModal isOpen={loading}>
                    Hold on tight, we’re processing {gift.items.length} gift{' '}
                    {gift.items.length === 1 ? 'purchase' : 'purchases'}
                </LoadingModal>

                {isCaptchaEnabled ? <CaptchaModal isOpen={openCaptcha} onSuccess={this.handleCaptchaSuccess} /> : null}

                <div className="px-3 px-sm-5 pb-3 pb-sm-5">
                    <Legend>Gift Details</Legend>
                    {model ? (
                        <List>
                            {model.items.map((giftItem, giftItemIndex) => (
                                <div key={giftItemIndex} className={classNames(styles.item, 'py-4')}>
                                    <GiftItem
                                        giftItem={giftItem}
                                        giftItemIndex={giftItemIndex}
                                        showEditButton={showButtons}
                                        showDeleteButton={showButtons && model.items.length > 1}
                                        onChange={this.handleGiftItemChange}
                                        showCode={
                                            context === BootstrapContext.PHYSICAL_GIFT_CARD ||
                                            context === BootstrapContext.SELLER
                                        }
                                    />
                                </div>
                            ))}
                        </List>
                    ) : null}

                    <Formik
                        initialValues={this.getInitialValues()}
                        validationSchema={this.getValidationSchema()}
                        onSubmit={this.handleGiftSubmit}
                    >
                        <Form>
                            <Legend className="mb-5">
                                {context === BootstrapContext.PHYSICAL_GIFT_CARD ? 'Customer Details' : 'From'}
                            </Legend>

                            <CustomerForm className="mb-5" context={context} showCheckbox={!customerEmailRequired} />

                            <Legend
                                className="mb-5"
                                right={
                                    <span className="text-primary">
                                        Total: <Currency>{payableAmount}</Currency>
                                    </span>
                                }
                            >
                                {context === BootstrapContext.SELLER ? 'Collect Payment' : 'Payment'}
                            </Legend>

                            {paymentRequired ? (
                                <Row className="mb-5">
                                    <Col md={7} xl={6}>
                                        <PaymentInput />
                                    </Col>
                                </Row>
                            ) : null}

                            <PaymentPageButtons
                                context={context}
                                loading={loading}
                                payableAmount={payableAmount}
                                onAddAnotherGiftClick={this.handleAddAnotherGiftClick}
                                onBackClick={this.handleBackClick}
                                isValid={areGiftItemsValid}
                                allowMultiItemGifts={allowMultiItemGifts}
                            />
                        </Form>
                    </Formik>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = state => ({
    gift: state.gift,
    request: state.request,
    payment: state.payment,
    isThreeDSecureEnabled: isThreeDSecureEnabled(state),
    canUseEMVPayment: canUseEMVPayment(state),
    giftAmount: getGiftAmount(state),
    payableAmount: getPayableAmount(state),
    phoneRequired: _.get(state.seller, 'preferences.gift.customerPhoneRequired', false),
    isCaptchaEnabled: isCaptchaEnabled(state),
    paymentTokenization: getPaymentTokenization(state.seller),
    context: state.bootstrap.context,
    currency: state.seller.currency,
    areGiftItemsValid: areGiftItemsValid(state),
    allowMultiItemGifts: allowMultiItemGifts(state),
    customPaymentMethods: state.seller.paymentMethods || null,
    paymentRequired: getGiftAmount(state) > 0,
    customerEmailRequired: isCustomerEmailRequired(state),
});

const mapDispatchToProps = {
    updateGift,
    updateGiftItem,
    removeGiftItem,
    saveGift,
    showError,
    releaseGift,
    commitGift,
    changePaymentMethod,
    cancelPaymentCollection,
    setGiftError,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(PaymentPage));
