import { InputGroup, InputGroupAddon } from 'reactstrap';
import { getSymbol } from '../../utils/currency';
import NumberFormat from 'react-number-format';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getCurrency } from '../../selectors/sellerSelectors';

class CurrencyInput extends Component {
    getSymbol() {
        const { currency } = this.props;
        return getSymbol(currency);
    }

    render() {
        const { onChange, value, style, className, isInvalid, inputRef, allowNegative = false } = this.props;

        return (
            <InputGroup style={style} className={className} notranslate="">
                <InputGroupAddon addonType="prepend">{this.getSymbol()}</InputGroupAddon>

                <NumberFormat
                    aria-label={'Choose An Amount'}
                    className={classNames('form-control', { 'is-invalid': isInvalid })}
                    onValueChange={onChange}
                    value={value}
                    getInputRef={inputRef}
                    decimalScale={2}
                    allowNegative={allowNegative}
                    thousandSeparator
                    fixedDecimalScale
                />
            </InputGroup>
        );
    }
}

const mapStateToProps = state => ({
    currency: getCurrency(state),
});

export default connect(mapStateToProps)(CurrencyInput);
