import { BootstrapContext } from '../actions/bootstrapActions';
import queryString from 'query-string';

const getContext = params => {
    if (params.apiKey && params.gift && params.value) {
        return BootstrapContext.PHYSICAL_GIFT_CARD;
    } else if (params.apiKey && params.seller) {
        return BootstrapContext.SELLER;
    } else if (params.button) {
        return BootstrapContext.BUTTON;
    } else if (params.seller) {
        return BootstrapContext.CHECKOUT;
    }

    return null;
};

const getQueryParams = () => {
    const hashParams = window.location.hash.substring(window.location.hash.indexOf('?') + 1);
    const params = queryString.parse(hashParams, { parseBooleans: true, parseNumbers: true });

    if (params.value) {
        params.value = parseFloat(params.value);
    }

    const iframe = window !== window.top;
    const context = getContext(params);
    return { ...params, context, iframe };
};

export default getQueryParams;
