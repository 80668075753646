import { FormFeedback, Input } from 'reactstrap';
import React, { Fragment } from 'react';
import { Field, getIn } from 'formik';

const FormField = ({ name, ...rest }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const isTouched = getIn(form.touched, field.name);
                const error = (isTouched || form.submitCount > 0) && getIn(form.errors, field.name);
                const value = field.value || '';

                return (
                    <Fragment>
                        <Input {...field} value={value} invalid={!!error} {...rest} />
                        <FormFeedback>{error}</FormFeedback>
                    </Fragment>
                );
            }}
        </Field>
    );
};

export default FormField;
