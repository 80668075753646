import { showLoader, hideLoader, scrollUp } from '../../actions/socketActions';
import { Modal as BootstrapModal } from 'reactstrap';
import React, { Component } from 'react';
import styles from './Modal.module.scss';
import { connect } from 'react-redux';
import classNames from 'classnames';

class Modal extends Component {
    componentDidUpdate(prevProps) {
        const { isOpen, showLoader, hideLoader, scrollUp } = this.props;

        if (isOpen !== prevProps.isOpen) {
            if (isOpen) {
                showLoader();
                scrollUp();
            } else {
                hideLoader();
            }
        }
    }

    componentWillUnmount() {
        this.props.hideLoader();
    }

    render() {
        const { className, dispatch, showLoader, hideLoader, scrollUp, ...rest } = this.props;
        return <BootstrapModal className={classNames(className, styles.main)} {...rest} />;
    }
}

const mapDispatchToProps = {
    showLoader,
    hideLoader,
    scrollUp,
};

export default connect(null, mapDispatchToProps)(Modal);
