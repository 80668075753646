import { scrollUp } from '../../actions/socketActions';
import { connect } from 'react-redux';
import { useEffect } from 'react';

const ScrollToTop = ({ children, location, scrollUp }) => {
    useEffect(() => {
        scrollUp();
    }, [scrollUp, location.pathname]);

    return children;
};

const mapDispatchToProps = {
    scrollUp,
};

export default connect(null, mapDispatchToProps)(ScrollToTop);
