// Main stylesheet must be imported first so components can override it.
import './styles/index.scss';

import createStore from './store/createStore';
import magensaApi from './api/magensaApi';
import Root from './components/Root';
import xolaApi from './api/xolaApi';
import ReactDOM from 'react-dom';
import './utils/resizeObserver';
import React from 'react';

const rootElement = document.getElementById('root');
const store = createStore();
magensaApi.setStore(store);
xolaApi.setStore(store);

if (process.env.NODE_ENV === 'development') {
    // To help with general debugging environment
    window.getState = store.getState;
}

// Enable Hot Module Replacement for components.
// Will not be included on production build.
if (module.hot) {
    module.hot.accept('./components/Root', () => {
        const Root = require('./components/Root').default;
        ReactDOM.render(<Root store={store} />, rootElement);
    });
}

ReactDOM.render(<Root store={store} />, rootElement);
