import { PaymentMethod } from '../actions/giftActions';
import { object, string, setLocale } from 'yup';
import cardValidator from 'card-validator';
import _ from 'lodash';

setLocale({
    mixed: {
        required: 'Required',
    },
});

const cardSchema = object().shape({
    billingName: string().required(),

    number: string()
        .required()
        .when('encrypted', encrypted => {
            if (encrypted) {
                return string().required();
            }

            return string().test('isCardNumberValid', 'Invalid number', value => {
                return value ? cardValidator.number(value).isValid : true;
            });
        }),

    expiryMonth: string().required(),

    expiryYear: string()
        .required()
        .when('expiryMonth', expiryMonth => {
            return string().test('isExpirationDateValid', 'Invalid date', expiryYear => {
                return cardValidator.expirationDate({ month: expiryMonth, year: expiryYear }).isValid;
            });
        }),

    cvv: string()
        .required()
        .when('number', number => {
            const { card } = cardValidator.number(number);

            return string()
                .required()
                .test('isCodeValid', 'Invalid CVC', cvv => {
                    return cardValidator.cvv(cvv, card ? card.code.size : 3).isValid;
                });
        }),

    encrypted: string(),
});

const stripeElementsCardSchema = object().shape({
    billingName: string(),
});

const paymentSchema = ({
    phoneRequired = false,
    paymentRequired = true,
    stripeElementsEnabled = false,
    customPaymentMethods = null,
    customerEmailRequired = false,
}) => {
    let transformedCustomPaymentMethods = {};
    if (customPaymentMethods) {
        customPaymentMethods.forEach(method => {
            transformedCustomPaymentMethods[method.id] = method.id;
        });
    }
    return object().shape({
        customerName: string().required(),

        customerEmail: customerEmailRequired
            ? string()
                  .email('Invalid email')
                  .required()
            : string()
                  .email('Invalid email')
                  .when('sendToCustomer', {
                      is: true,
                      then: string().required(),
                  }),

        phone: phoneRequired ? string().required() : string(),

        payment: paymentRequired
            ? object().shape({
                  method: string().oneOf(_.values({ ...PaymentMethod, ...transformedCustomPaymentMethods })),
                  comment: string().max(30, 'Maximum is 30 characters'),

                  card: object().when('method', {
                      is: PaymentMethod.CREDIT_CARD,
                      then: stripeElementsEnabled ? stripeElementsCardSchema : cardSchema,
                  }),
              })
            : object(),
    });
};

export default paymentSchema;
