import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Currency from './Currency';
import { getMaximumCustomAmount, getMinimumCustomAmount } from '../selectors/giftSelector';

class CustomGiftAmountValidationError extends Component {
    render() {
        const { baseAmount, giftItemAmount, maximumCustomAmount, minimumCustomAmount } = this.props;
        const isAmountExceedsLimit = giftItemAmount > maximumCustomAmount;
        const isAmountLessThanMinLimit = baseAmount < minimumCustomAmount;

        return (
            <Fragment>
                {isAmountExceedsLimit ? (
                    <small className="text-danger">
                        Maximum allowed amount including fees is <Currency>{maximumCustomAmount}</Currency>
                    </small>
                ) : null}

                {isAmountLessThanMinLimit ? (
                    <small className="text-danger">
                        Minimum amount is <Currency>{minimumCustomAmount}</Currency>
                    </small>
                ) : null}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    maximumCustomAmount: getMaximumCustomAmount(state.seller),
    minimumCustomAmount: getMinimumCustomAmount(state.seller),
});

export default connect(mapStateToProps)(CustomGiftAmountValidationError);
