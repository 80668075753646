import CouponRestrictions from '../CouponRestrictions/CouponRestrictions';
import { ListItem, ListItemContent, ListItemImage } from '../List';
import { GiftOfferType } from '../../actions/giftOfferActions';
import Currency, { CurrencyInput } from '../Currency';
import GiftItemBreakdown from './GiftItemBreakdown';
import React, { Component, createRef } from 'react';
import GiftItemButtons from './GiftItemButtons';
import GiftCodeInput from '../GiftCodeInput';
import ProductImage from '../ProductImage';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

class GiftOfferGiftItem extends Component {
    el = createRef();

    handleAmountChange = values => {
        const { onChange, giftItemIndex } = this.props;
        onChange(giftItemIndex, { baseAmount: values.floatValue || 0 });
    };

    handleCodeChange = code => {
        const { onChange, giftItemIndex } = this.props;
        onChange(giftItemIndex, { code });
    };

    isBaseAmountValid() {
        const { product, giftItem } = this.props;

        if (product.type === GiftOfferType.VARIABLE) {
            if (product.minimumPrice) {
                return giftItem.baseAmount >= product.minimumPrice;
            }

            return giftItem.baseAmount > 0;
        }

        return true;
    }

    render() {
        const {
            giftItemIndex,
            giftItem,
            product,
            showEditButton,
            showDeleteButton,
            showCode,
            disableCode,
            showInput,
            isDuplicateCode,
        } = this.props;

        const isBaseAmountValid = this.isBaseAmountValid();
        const productTitle =
            product.type === GiftOfferType.VARIABLE && isBaseAmountValid ? (
                <>
                    <Currency>{giftItem.baseAmount}</Currency> GIFT CARD{' '}
                </>
            ) : (
                product.name
            );

        return (
            <ListItem padding={false}>
                <ListItemImage>
                    <ProductImage product={product} />

                    <GiftItemButtons
                        giftItemIndex={giftItemIndex}
                        showEditButton={showEditButton}
                        showDeleteButton={showDeleteButton}
                    />
                </ListItemImage>

                <ListItemContent>
                    <Row>
                        <Col md={6}>
                            <h5 notranslate="">{productTitle}</h5>

                            <div className="text-muted mb-2" notranslate="">
                                {product.desc}
                            </div>

                            <CouponRestrictions className="mb-3" couponRestrictions={product.couponRestrictions} />

                            {showCode ? (
                                <GiftCodeInput
                                    style={{ maxWidth: 200 }}
                                    onChange={this.handleCodeChange}
                                    value={giftItem.code}
                                    disabled={disableCode}
                                    error={isDuplicateCode ? 'Duplicate code' : null}
                                />
                            ) : null}

                            {showInput && product.type === GiftOfferType.VARIABLE ? (
                                <div className="mb-2">
                                    <CurrencyInput
                                        aria-label={'Choose an amount'}
                                        style={{ maxWidth: 200 }}
                                        className="mt-1"
                                        isInvalid={!this.isBaseAmountValid()}
                                        onChange={this.handleAmountChange}
                                        value={giftItem.baseAmount}
                                    />

                                    {isBaseAmountValid ? null : (
                                        <small className="text-danger">
                                            {product.minimumPrice ? (
                                                <span>
                                                    Minimum amount is <Currency>{product.minimumPrice}</Currency>
                                                </span>
                                            ) : (
                                                'Required'
                                            )}
                                        </small>
                                    )}
                                </div>
                            ) : null}
                        </Col>

                        <Col md={6}>
                            <GiftItemBreakdown giftItem={giftItem} isCustomAmountBreakdown={true} />
                        </Col>
                    </Row>
                </ListItemContent>
            </ListItem>
        );
    }
}

const mapStateToProps = state => ({
    products: state.products.data,
});

export default connect(mapStateToProps)(GiftOfferGiftItem);
