/**
 * Detects if we are running within a Xola Mobile application.
 */
const isMobileApp = () => {
    // The user agent for a Xola app is of the syntax: XolaMerchant-<OS>/<version>
    // e.g: XolaMerchant-Android/1.0
    return /XolaMerchant-(iOS|Android)/i.test(navigator.userAgent);
};

export default isMobileApp;
