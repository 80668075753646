import React, { Component } from 'react';
import classNames from 'classnames';

class ListItemContent extends Component {
    render() {
        const { className, ...rest } = this.props;
        return <div className={classNames('flex-fill', classNames)} {...rest} />;
    }
}

export default ListItemContent;
