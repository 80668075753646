import { isSellerContext } from '../selectors/bootstrapSelectors';
import xolaImage from '../images/xola.png';
import styles from './Footer.module.scss';
import React, { Component } from 'react';
import xolaUrl from '../utils/xolaUrl';
import { connect } from 'react-redux';
import classNames from 'classnames';

class Footer extends Component {
    render() {
        const { hideFooter } = this.props;

        if (hideFooter) {
            return null;
        }

        return (
            <div className={classNames(styles.main, 'text-muted p-4')}>
                Powered by
                <a href={xolaUrl()} target="_blank" rel="noopener noreferrer">
                    <img className={classNames('ml-2', styles.logo)} src={xolaImage} alt="Xola" />
                </a>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    hideFooter: isSellerContext(state),
});

export default connect(mapStateToProps)(Footer);
