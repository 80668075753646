import XWM from 'xolabot-xwm';

let xwm;

export const initializeXWM = token => {
    xwm = XWM.fromToken(token);
};

export const getXWM = () => {
    return xwm;
};
