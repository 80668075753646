import SumoLogger from 'sumo-logger';
import config from './config';

const logger = config.sumologic.endpoint
    ? new SumoLogger({
          endpoint: config.sumologic.endpoint,
      })
    : console;

export default logger;
