import { DISMISS_LATEST_ERROR, SHOW_ERROR } from '../actions/errorActions';
import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

export const initialState = [];

const errorsReducer = createReducer(initialState, {
    [DISMISS_LATEST_ERROR](state) {
        return _.remove(state, state.length - 1);
    },

    [SHOW_ERROR](state, { error }) {
        state.push(error);
    },
});

export default errorsReducer;
