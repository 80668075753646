import bootstrapReducer from '../reducers/bootstrapReducer';
import progressReducer from '../reducers/progressReducer';
import productsReducer from '../reducers/productsReducer';
import captchaReducer from '../reducers/captchaReducer';
import errorsReducer from '../reducers/errorsReducer';
import sellerReducer from '../reducers/sellerReducer';
import buttonReducer from '../reducers/buttonReducer';
import { configureStore } from 'redux-starter-kit';
import giftReducer from '../reducers/giftReducer';
import feesReducer from '../reducers/feesReducer';
import userReducer from '../reducers/userReducer';
import paymentReducer from '../reducers/paymentReducer';
import requestReducer from '../reducers/requestReducer';

const rootReducer = {
    bootstrap: bootstrapReducer,
    products: productsReducer,
    gift: giftReducer,
    seller: sellerReducer,
    fees: feesReducer,
    errors: errorsReducer,
    button: buttonReducer,
    user: userReducer,
    progress: progressReducer,
    captcha: captchaReducer,
    payment: paymentReducer,
    request: requestReducer,
};

const createStore = (preloadedState = {}) => {
    return configureStore({
        preloadedState,
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
    });
};

export default createStore;
