import CouponRestrictions from '../CouponRestrictions/CouponRestrictions';
import { ListItem, ListItemImage, ListItemContent } from '../List';
import { GiftOfferType } from '../../actions/giftOfferActions';
import ProductItemBreakdown from './ProductItemBreakdown';
import Currency, { CurrencyInput } from '../Currency';
import React, { Component, createRef } from 'react';
import { Row, Col, Button } from 'reactstrap';
import ProductImage from '../ProductImage';
import _ from 'lodash';

class GiftOfferProductItem extends Component {
    state = {
        baseAmount: this.props.product.minimumPrice || this.props.product.price || null,
        touched: false,
    };

    el = createRef();

    handleAmountChange = values => {
        this.setState({
            baseAmount: _.isNil(values.floatValue) ? null : values.floatValue,
            touched: true,
        });
    };

    handleSelect = () => {
        const { product } = this.props;

        if (product.type === GiftOfferType.FIXED) {
            return this.props.onSelect(this.props.product, this.getBaseAmount());
        }

        if (this.isBaseAmountValid()) {
            this.props.onSelect(this.props.product, this.getBaseAmount());
        } else {
            this.setState({ touched: true });
            this.el.current.focus();
        }
    };

    handleClick = e => {
        const { product, onClick } = this.props;

        if (product.type === GiftOfferType.FIXED) {
            this.handleSelect();
        } else {
            onClick(e);
        }
    };

    getBaseAmount() {
        const { product } = this.props;

        if (product.type === GiftOfferType.VARIABLE) {
            return this.state.baseAmount;
        }

        return product.price;
    }

    isBaseAmountValid() {
        const { product } = this.props;

        if (product.type === GiftOfferType.VARIABLE) {
            if (product.minimumPrice) {
                return this.getBaseAmount() >= product.minimumPrice;
            }

            return this.getBaseAmount() > 0;
        }

        return true;
    }

    showError() {
        return this.state.touched && !this.isBaseAmountValid();
    }

    getErrorMessage() {
        const { product } = this.props;
        if (product.minimumPrice) {
            return (
                <span>
                    Minimum amount is <Currency>{product.minimumPrice}</Currency>
                </span>
            );
        }

        if (this.state.baseAmount === 0) {
            return (
                <span>
                    Amount should be greater than <Currency>0</Currency>
                </span>
            );
        }

        return 'Required';
    }

    render() {
        const { product, isSelected } = this.props;
        const baseAmount = this.getBaseAmount();
        const showError = this.showError();
        const errorMsg = this.getErrorMessage();

        return (
            <ListItem onClick={this.handleClick} isSelected={isSelected} align hover>
                <ListItemImage>
                    <ProductImage product={product} />
                </ListItemImage>

                <ListItemContent>
                    <Row className="align-items-center">
                        <Col md={5}>
                            <h5 notranslate="">{product.name}</h5>

                            <div className="text-muted mb-2" notranslate="">
                                {product.desc}
                            </div>

                            <CouponRestrictions className="mb-3" couponRestrictions={product.couponRestrictions} />

                            {product.type === GiftOfferType.VARIABLE ? (
                                <div className="mb-2">
                                    <CurrencyInput
                                        style={{ maxWidth: 200 }}
                                        className="mt-1"
                                        onChange={this.handleAmountChange}
                                        value={this.state.baseAmount}
                                        isInvalid={showError}
                                        inputRef={this.el}
                                    />

                                    {showError ? <small className="text-danger">{errorMsg}</small> : null}
                                </div>
                            ) : null}
                        </Col>

                        <Col className="mb-2 mb-md-0" md={4}>
                            <ProductItemBreakdown
                                product={product}
                                baseAmount={baseAmount}
                                isCustomAmountBreakdown={true}
                            />
                        </Col>

                        <Col md={{ offset: 1, size: 2 }}>
                            {isSelected && product.type === GiftOfferType.VARIABLE ? (
                                <Button color="success" onClick={this.handleSelect} block>
                                    Select
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                </ListItemContent>
            </ListItem>
        );
    }
}

export default GiftOfferProductItem;
