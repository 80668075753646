import styles from './PaymentMethodButton.module.scss';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import domId from '../../utils/domId';

class PaymentMethodButton extends Component {
    id = domId(this);

    render() {
        const { className, title, icon, checked, onClick } = this.props;

        return (
            <div className={classNames(className, 'd-inline-block text-center mr-2')}>
                <Button
                    onClick={onClick}
                    type="button"
                    className={classNames(styles.main, 'rounded-lg py-1 mb-1', {
                        [styles.mainSelected]: checked,
                        'bg-light border-light': !checked,
                        'border-primary': checked,
                    })}
                    color="primary"
                    outline
                >
                    {icon}
                    <small className="d-block text-dark text-uppercase">{title}</small>
                </Button>

                <div onClick={onClick} role="button">
                    {checked ? (
                        <i className="fas fa-dot-circle text-primary" />
                    ) : (
                        <i className="far fa-circle text-secondary" />
                    )}
                </div>
            </div>
        );
    }
}

export default PaymentMethodButton;
