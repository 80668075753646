import xolaApi from '../api/xolaApi';
import { showError } from './errorActions';

export const FETCH_GIFT_OFFERS_REQUESTED = 'FETCH_GIFT_OFFERS_REQUESTED';
export const FETCH_GIFT_OFFERS_SUCCEEDED = 'FETCH_GIFT_OFFERS_SUCCEEDED';
export const FETCH_GIFT_OFFERS_FAILED = 'FETCH_GIFT_OFFERS_FAILED';

/**
 * Fetch gift offers.
 */
export const fetchGiftOffers = giftOfferParams => async dispatch => {
    const params = { expand: 'experience', limit: 100, ...giftOfferParams };
    try {
        dispatch({ type: FETCH_GIFT_OFFERS_REQUESTED });
        let url = '/api/giftOffers?';
        for (const param in params) {
            url += param + '=' + params[param] + '&';
        }
        url = url.replace(/&$/, '');
        const giftOffers = [];
        while (url) {
            const { data } = await xolaApi.get(url);
            giftOffers.push(...data.data);
            url = data.paging.next;
        }

        dispatch({ type: FETCH_GIFT_OFFERS_SUCCEEDED, giftOffers });
    } catch (error) {
        dispatch({ type: FETCH_GIFT_OFFERS_FAILED });
        dispatch(showError('Failed to Load Gift Offers'), error);
    }
};

export const GiftOfferType = {
    FIXED: 'fixed',
    VARIABLE: 'variable',
};
