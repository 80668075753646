import _ from 'lodash';

class GoogleAnalyticsGift {
    /**
     * @param {Gift} gift
     */
    constructor(gift) {
        this.gift = gift;
    }

    getProducts() {
        return this.gift.items.map(item => ({
            id: item.id,
            name: 'Gift ' + item.coupon.code.toUpperCase(),
            sku: item.coupon.id,
            price: item.baseAmount,
            quantity: 1,
            category: 'Gift',
        }));
    }

    getTaxAmount() {
        // Only experience gifts can have taxes.
        return _.sumBy(this.gift.items, item => {
            return item.baseAmount < item.amount ? item.amount - item.baseAmount : 0;
        });
    }

    toGoogleAnalyticsObject() {
        return {
            transaction: {
                id: this.gift.id,
                affiliation: 'Xola',
                revenue: this.gift.amount,
                tax: this.getTaxAmount(),
                currency: this.gift.currency,
            },
            products: this.getProducts(),
        };
    }

    toGoogleTagManagerObject() {
        return {
            transactionId: this.gift.id,
            transactionAffiliation: 'Xola',
            transactionTotal: this.gift.amount,
            transactionTax: this.getTaxAmount(),
            transactionCurrency: this.gift.currency,
            transactionProducts: this.getProducts(),
        };
    }
}

export default GoogleAnalyticsGift;
