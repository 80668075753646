import facebookPixelTracker from '../trackers/FacebookPixel/facebookPixelTracker';
import { showError } from './errorActions';
import xolaApi from '../api/xolaApi';
import localize from '../localize';

export const FETCH_SELLER_REQUESTED = 'FETCH_SELLER_REQUESTED';
export const FETCH_SELLER_SUCCEEDED = 'FETCH_SELLER_SUCCEEDED';
export const FETCH_SELLER_FAILED = 'FETCH_SELLER_FAILED';

/**
 * Fetch seller by ID.
 * @param {string} id Seller ID.
 */
export const fetchSeller = id => async (dispatch, getState) => {
    try {
        dispatch({ type: FETCH_SELLER_REQUESTED, id });
        const { data: seller } = await xolaApi.get(`/api/sellers/${id}`);
        dispatch({ type: FETCH_SELLER_SUCCEEDED, seller });

        // Initialize localization.
        localize(seller);

        // Initialize Facebook Pixel.
        facebookPixelTracker.init(seller, getState().bootstrap);
    } catch (error) {
        dispatch({ type: FETCH_SELLER_FAILED, id });
        dispatch(showError('Failed to Load the Seller', error));
    }
};
