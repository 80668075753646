import CardSwipeListener from '../../utils/CardSwipeListener';
import { Component } from 'react';

class CreditCardSwiper extends Component {
    componentDidMount() {
        const { enable, onSwipe, onError, isTokenizationEnabled } = this.props;

        if (enable) {
            this.listener = new CardSwipeListener({
                isTokenizationEnabled,
                onSwipe,
                onError,
            });

            this.listener.enable();
        }
    }

    componentWillUnmount() {
        if (this.listener) {
            this.listener.disable();
        }
    }

    render() {
        return null;
    }
}

export default CreditCardSwiper;
