import React, { Component, Fragment } from 'react';
import { Tooltip } from 'reactstrap';
import domId from '../utils/domId';

class HoverTooltip extends Component {
    state = { isOpen: false };

    id = domId(this);

    handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        const { className, content, children, placement } = this.props;
        const { isOpen } = this.state;

        if (!content) {
            return children;
        }

        return (
            <Fragment>
                <Tooltip
                    placement={placement}
                    target={this.id}
                    trigger="hover"
                    toggle={this.handleToggle}
                    isOpen={isOpen}
                >
                    {content}
                </Tooltip>

                <span className={className} id={this.id}>
                    {children}
                </span>
            </Fragment>
        );
    }
}

export default HoverTooltip;
