import { getPriceTypeConstraint, PriceSchemePriceType, getPriceScheme } from '../../selectors/experienceSelector';
import { ListItem, ListItemImage, ListItemContent } from '../List';
import ProductImage from '../ProductImage';
import React, { Component } from 'react';

class ExperienceProductItem extends Component {
    handleClick = () => {
        const { product, onSelect } = this.props;
        const experience = product.experience;
        const priceTypeConstraint = getPriceTypeConstraint(experience);
        let quantity = 1;

        if (priceTypeConstraint?.priceType === PriceSchemePriceType.PERSON) {
            quantity = experience.group.orderMin || 1;
        }

        const { price } = getPriceScheme(experience, quantity);

        onSelect(product, price * quantity);
    };

    render() {
        const { product } = this.props;
        const experience = product.experience;

        return (
            <ListItem onClick={this.handleClick} align hover>
                <ListItemImage>
                    <ProductImage product={experience} />
                </ListItemImage>

                <ListItemContent>
                    <h5 notranslate="">{experience.name}</h5>
                    <span className="text-muted" notranslate="">
                        {experience.excerpt}
                    </span>
                </ListItemContent>
            </ListItem>
        );
    }
}

export default ExperienceProductItem;
