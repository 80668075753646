import styles from './CartButton.module.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import classNames from 'classnames';
import { Badge } from 'reactstrap';

class CartButton extends Component {
    render() {
        const { count } = this.props;

        if (count < 2) {
            return null;
        }

        return (
            <Link to="/payment" className={classNames(styles.main, 'btn btn-success text-nowrap')}>
                <i className="fas fa-shopping-cart mr-2" />
                <span className="mr-2">Cart</span>

                <Badge className={classNames(styles.badge, 'mr-2')} pill>
                    {count}
                </Badge>

                <i className="fas fa-chevron-right" />
            </Link>
        );
    }
}

const mapStateToProps = state => ({
    count: state.gift.items.length,
});

export default connect(mapStateToProps)(CartButton);
